import anime from 'animejs/lib/anime.es';
import { OBSERVER } from '../plugins';

// Permet de changer la visibilité du champ autre
export default function otherVisibility() {
  const toggleOtherVisibility = (e) => {
    const radioOrCheckbox = e.currentTarget;
    const formRadioOrCheckbox = radioOrCheckbox.closest('form');
    const nameRadioOrCheckbox = radioOrCheckbox.getAttribute('name');
    const collectionRadiosOrCheckboxes = document.querySelectorAll(`input[name="${nameRadioOrCheckbox}"]`);
    const classFieldOther = 'js-field-other';
    const classInputValueOther = 'js-input-value-other';
    const classInputFieldOther = 'js-input-field-other';
    let i;

    for (i = 0; i < collectionRadiosOrCheckboxes.length; i += 1) {
      if (collectionRadiosOrCheckboxes[i].classList.contains(classInputValueOther)) {
        if (collectionRadiosOrCheckboxes[i].checked) {
          if (collectionRadiosOrCheckboxes[i] === radioOrCheckbox) {
            formRadioOrCheckbox.querySelector(`.${classInputFieldOther} input`).focus();

            anime.timeline({
              targets: formRadioOrCheckbox.querySelector(`.${classFieldOther}`),
              duration: 300,
              easing: 'easeOutCubic',
            }).add({
              'z-index': 1,
              height: '150px',
              opacity: 1,
            });
          }
        } else {
          formRadioOrCheckbox.querySelector(`.${classInputFieldOther}`).dataset.inputvalue = '';
          formRadioOrCheckbox.querySelector(`.${classInputFieldOther} input`).value = '';
          formRadioOrCheckbox.querySelector(`.${classInputFieldOther} input`).classList.remove('valid');

          anime.timeline({
            targets: formRadioOrCheckbox.querySelector(`.${classFieldOther}`),
            duration: 300,
            easing: 'easeOutCubic',
          }).add({
            'z-index': -1,
            height: '0px',
            opacity: 0,
          });
        }
      }
    }
  };

  OBSERVER.add({
    name: 'otherVisibility',
    events: 'change',
    targets: 'input[type="radio"], input[type="checkbox"]',
    function: toggleOtherVisibility,
  });
  OBSERVER.on('otherVisibility');
}
