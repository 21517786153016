import validationMessages from './validationMessages';
import validation from './validations';

// La validation du champ d'inscription à l'infolettre dans le footer
export default function formNewsletterFooter() {
  const m = validationMessages();

  const rules = {
    email: { required: true, email: true, courriel: true },
  };
  const messages = {
    email: { required: m.required, email: m.email, courriel: m.email },
  };

  validation('#form-newsletter-footer', '#form-newsletter-footer-submit', rules, messages);
}
