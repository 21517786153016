import { OBSERVER } from '../plugins';

// Modification de la hauteur d'un textarea selon son contenu
export default function textareaHeight() {
  const formTextareas = document.querySelectorAll('textarea');

  for (let i = 0; i < formTextareas.length; i += 1) {
    formTextareas[i].style.height = `${Math.max(formTextareas[i].scrollHeight, 43)}px`;
  }

  const onInput = (e) => {
    e.currentTarget.style.height = '5px';
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 43 ? 43 : e.currentTarget.scrollHeight}px`;
  };

  OBSERVER.add({
    name: 'textareaHeight',
    events: 'input',
    targets: 'textarea',
    function: onInput,
  });
  OBSERVER.on('textareaHeight');
}
