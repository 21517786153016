import formatBytes from '@blankonumerique/blanko-scripts/format-bytes';
import { OBSERVER } from '../plugins';

// Permet de changer le label des inputs files
export default function fileUpload() {
  const clear = (name, element, currentLabelInnerHTML) => {
    const paramEl = element;
    paramEl.value = '';
    paramEl.nextElementSibling.querySelector('.js-file-text').innerHTML = currentLabelInnerHTML;
    paramEl.parentNode.dataset.file = '';
    OBSERVER.off(`clear${name}`);
  };

  const changeLabel = (e) => {
    const self = e;
    const label = e.currentTarget.nextElementSibling.querySelector('.js-file-text');
    const currentLabelInnerHTML = label.innerHTML;
    let i;
    let newLabel = '';
    const fileLength = e.currentTarget.files.length;

    if ('files' in e.currentTarget) {
      if (fileLength !== 0) {
        for (i = 0; i < fileLength; i += 1) {
          const file = e.currentTarget.files[i];
          const name = e.currentTarget.getAttribute('name');
          newLabel += `${i + 1}. `;

          if ('name' in file) newLabel += `fichier: ${file.name}, `;
          if ('size' in file) {
            newLabel += `poids: ${formatBytes(file.size)} \n`;
          }

          const onClear = () => clear(name, self.target, currentLabelInnerHTML);

          OBSERVER.add({
            name: `clear${name}`,
            events: 'click',
            targets: e.currentTarget.previousElementSibling,
            function: onClear,
          });

          OBSERVER.on(`clear${name}`);
        }
        e.currentTarget.parentNode.dataset.file = newLabel;
        label.innerText = newLabel;
      }
    }
  };

  OBSERVER.add({
    name: 'fileUpload',
    events: 'change',
    targets: 'input[type=file].js-file-field-input',
    function: changeLabel,
  });
  OBSERVER.on('fileUpload');
}
