import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation/dist/additional-methods';

// La validation des courriels
function emailRule() {
  $.validator.addMethod('courriel', function methodEmailRule(value, element) {
    // eslint-disable-next-line max-len
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value);
  });
}

// La validation du poid des fichiers
function fileSizeRule() {
  $.validator.addMethod('filesize', function methodFileSizeRule(value, element, param) {
    return this.optional(element) || (element.files[0].size <= param);
  }, 'La taille maximale du fichier doit être 5MB');
}

// Fonction exécutant la validation de chaque formulaire
export default function validation(formElement, submit, rules, messages, handler) {
  emailRule();
  fileSizeRule();

  $.validator.setDefaults({
    ignore: [],
  });
  $(formElement).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: (_, validator) => {
      if (!validator.numberOfInvalids()) {
        return; // eslint-disable-next-line semi
      }

      const nbProjectError = validator.errorList.find((item) => item.element.name === 'nbProject');

      if (nbProjectError === undefined) {
        $('html, body').animate({ scrollTop: $(validator.errorList[0].element).offset().top - 50 }, 500);
      }
    },
    rules,
    messages,
    errorPlacement: (error, element) => {
      error.appendTo(element.closest('.js-field').find('.js-form-error'));
    },
    success: (_, element) => {
      // Enlever la classe d'erreur au champ
      const errorField = $(element).closest('.js-field');
      const className = errorField.attr('class').split(' ')[0];
      errorField.removeClass(`${className}--error`);

      // Mettre à jour les attributs et classes
      $(element).removeClass('error').addClass('valid');
      $(element).attr('aria-invalid', 'false');
    },
    highlight: (element) => {
      // Ajouter la classe d'erreur au champ
      const errorField = $(element).closest('.js-field');
      const className = errorField.attr('class').split(' ')[0];
      errorField.addClass(`${className}--error`);

      // Mettre à jour les attributs et classes
      $(element).removeClass('valid').addClass('error');
      $(element).attr('aria-invalid', 'true');
    },
    submitHandler: () => {
      if (typeof handler !== 'undefined') {
        handler();
        return false;
      }
      return true;
    },
  });
  // Mettre à jour la validation d'un select au change
  $('select:not(.no-validation)').on('change', function selectChange() {
    $(this).valid();
  });
  $(submit).on('click', () => {
    $(formElement).submit();
  });
}
