import typerSetup from '../vendor/typer';
import { OBSERVER } from '../plugins';

// Fonction permettant de changer la valeur du placeholder du champ recherche dans la recherche rapide de l'accueil
export default function homeFastSearchInputPlaceholder() {
  const homeFastSearchInput = document.querySelector('.js-home-fast-search-search-field-input');
  const homeFastSearchLabel = document.querySelector('.js-home-fast-search-search-field-label');

  function onResize() {
    if (window.matchMedia('(max-width: 600px)').matches) {
      homeFastSearchLabel.style.display = 'none';
      homeFastSearchInput.placeholder = 'Rechercher';
    } else {
      homeFastSearchLabel.style.display = 'block';
      homeFastSearchInput.placeholder = ' ';
    }
  }

  typerSetup();
  onResize();
  OBSERVER.add({
    name: 'homeFastSearchInputPlaceholder',
    events: 'resize',
    function: onResize,
  });
  OBSERVER.on('homeFastSearchInputPlaceholder');
}
