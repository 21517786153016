import { OBSERVER } from '../plugins';

// Permet de gérer la visibilité des alertes
export default function visibilityAlerts() {
  if (!document.querySelector('.js-alert[data-index="1"]')) {
    document.documentElement.style.setProperty('--alert-index-1-height', '0px');
  }

  const hideAlert = (e) => {
    const alert = e.currentTarget.closest('.js-alert');
    const alertIndex = alert.dataset.index;

    alert.classList.add('is-hide');
    document.documentElement.style.setProperty(`--alert-index-${alertIndex}-height`, '0px');
  };

  OBSERVER.add({
    name: 'visibilityAlerts',
    events: 'click',
    targets: '.js-close-alert',
    function: hideAlert,
  });
  OBSERVER.on('visibilityAlerts');
}
