import $ from 'jquery';
import anime from 'animejs/lib/anime.es';
import formatBytes from '@blankonumerique/blanko-scripts/format-bytes';
import { OBSERVER } from '../plugins';

// Permet d'ajouter plusieurs fichiers (sans Renatio)
export default function multipleFilesUpload() {
  const makeid = () => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const deleteItem = (e) => {
    const buttonInput = e.currentTarget.closest('.js-multiple-files-field').querySelector('.js-multiple-files-field-button-input');
    const containerList = e.currentTarget.closest('.js-multiple-files-field').querySelector('.js-multiple-files-field-files-list');
    const files = containerList.querySelectorAll('.js-multiple-files-field-file-instance');
    const { maxFiles } = e.currentTarget.dataset;

    anime.timeline({
      targets: e.currentTarget.closest('.js-multiple-files-field-file-instance'),
      duration: 300,
      easing: 'easeOutCubic',
    }).add({
      height: '0px',
      opacity: 0,
    }).finished.then(() => {
      e.target.closest('.js-multiple-files-field-file-instance').remove();

      if (maxFiles !== 'null' || files.length + 1 >= maxFiles) {
        buttonInput.removeAttribute('disabled');
      }
    });
  };

  const addItem = (e) => {
    const containerList = e.currentTarget.closest('.js-multiple-files-field').querySelector('.js-multiple-files-field-files-list');
    const files = containerList.querySelectorAll('.js-multiple-files-field-file-instance');
    const originalDataMaxFiles = e.currentTarget.dataset.maxFiles;

    if (originalDataMaxFiles === 'null' || files.length < originalDataMaxFiles) {
      const originalClass = e.currentTarget.className;
      const originalID = e.currentTarget.id;
      const originalName = e.currentTarget.name;
      const originalAccept = e.currentTarget.accept;
      const inputContainer = e.currentTarget.parentNode;
      const template = e.currentTarget.closest('.js-multiple-files-field').querySelector('.js-multiple-files-field-file-block-template').content.cloneNode(true);

      if ($(e.currentTarget).get(0).files.length > 0) {
        const id = makeid();
        const file = $(e.currentTarget).get(0).files[$(e.currentTarget).get(0).files.length - 1];

        let previewSrc = `/themes/${window.config.theme_path}/assets/medias/images/default/multiple-files-field-preview-default.png`;
        if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
          previewSrc = URL.createObjectURL(file);
        }

        const mainInput = document.querySelector('#form-contact-files.js-multiple-files-field-button-input.valid');
        if (mainInput) {
          mainInput.classList.remove('valid');
        }

        template.querySelector('.js-multiple-files-field-file-image').src = previewSrc;
        template.querySelector('.js-multiple-files-field-file-title').textContent = `${file.name}`;
        template.querySelector('.js-multiple-files-field-file-size').textContent = `${formatBytes(file.size)}`;
        template.querySelector('.js-multiple-files-field-file-instance').setAttribute('id', `container-${id}`);
        containerList.appendChild(template);
        e.currentTarget.setAttribute('id', id);
        document.querySelector(`#container-${id}`).appendChild(e.currentTarget);
        const height = `${document.querySelector(`#${id}`).parentNode.querySelector('.js-multiple-files-field-file-height').clientHeight}px`;

        anime.timeline({
          targets: document.querySelector(`#${id}`).parentNode,
          duration: 300,
          easing: 'easeOutCubic',
        }).add({
          height,
          opacity: 1,
        }).add({
          height: '100%',
        });
      }

      const newInput = document.createElement('input');
      newInput.setAttribute('class', originalClass);
      newInput.setAttribute('type', 'file');
      newInput.setAttribute('name', originalName);
      newInput.setAttribute('id', originalID);
      newInput.setAttribute('accept', originalAccept);
      newInput.setAttribute('data-max-files', originalDataMaxFiles);
      if (originalDataMaxFiles !== 'null' && files.length + 1 >= originalDataMaxFiles) {
        newInput.setAttribute('disabled', 'disabled');
      }

      inputContainer.prepend(newInput);
    }

    OBSERVER.off('multipleFilesUpload');
    OBSERVER.add({
      name: 'multipleFilesUpload',
      events: 'change',
      targets: 'input[type=file].js-multiple-files-field-button-input',
      function: addItem,
    });
    OBSERVER.add({
      name: 'multipleFilesUpload',
      events: 'click',
      targets: '.js-multiple-files-field-file-delete',
      function: deleteItem,
    });
    OBSERVER.on('multipleFilesUpload');
  };

  OBSERVER.add({
    name: 'multipleFilesUpload',
    events: 'change',
    targets: 'input[type=file].js-multiple-files-field-button-input',
    function: addItem,
  });
  OBSERVER.add({
    name: 'multipleFilesUpload',
    events: 'click',
    targets: '.js-multiple-files-field-file-delete',
    function: deleteItem,
  });
  OBSERVER.on('multipleFilesUpload');
}
