import Overlay from '../vendor/overlay';
import addDataSectionClosingAttributeToHtml from './addDataSectionClosingAttributeToHtml';
import { OBSERVER } from '../plugins';

// Fonction gérant l'overlay du menu desktop
export default function overlayMenuDesktop() {
  const menuDesktopSectionLeft = document.querySelectorAll('.js-overlay-menu-desktop-section-left');
  const menuDesktopSectionRight = document.querySelectorAll('.js-overlay-menu-desktop-section-right');
  const menuDesktopSubSectionLink = document.querySelectorAll('.js-overlay-menu-desktop-sub-section-link:not([href])');
  const menuDesktopSubSectionUnderContainer = document.querySelectorAll('.js-overlay-menu-desktop-sub-section-under-container');

  const targetMenuDesktopSection = '.js-overlay-menu-desktop-section';
  const targetMenuDesktopSectionLink = '.js-overlay-menu-desktop-sub-section-link:not([href])';
  const targetMenuDesktopSubSectionUnderContainer = '.js-overlay-menu-desktop-sub-section-under-container';

  const activeClassMenuDesktopSectionLink = 'is-active';
  const activeClassMenuDesktopSubSectionUnderContainer = 'is-show';

  const overlay = new Overlay({
    name: 'menu-desktop',
    events: {
      close: true,
      closeComplete: true,
      trigger: true,
    },
    click: {
      buttons: {
        close: 'a[href], .js-close-overlay-menu-desktop',
        trigger: '.js-trigger-overlay-menu-desktop',
        switch: '.js-trigger-overlay-search',
      },
    },
    animations: {
      selector: '.js-overlay-menu-desktop-sections',
      styles: [{
        property: 'height',
        value: 'dataset',
        easing: 'easeInOutQuart',
      }],
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
    },
  });

  overlay.init();

  // Fonction permettant de donner un attribut data section closing au html (mettre events closeComplete et trigger à true)
  addDataSectionClosingAttributeToHtml(overlay, 'Menu-desktop');

  // Cacher le menu sub-section actif
  function hideMenuSubsection(subSectionUnderContainer) {
    subSectionUnderContainer.classList.remove(activeClassMenuDesktopSubSectionUnderContainer);
    // eslint-disable-next-line no-param-reassign
    subSectionUnderContainer.scrollTop = 0;
  }

  // Cacher le menu sub-section actif et enlever toutes les classes en lien direct avec l'apparition d'un menu sub-section
  function hideAllMenuDesktopSubSection() {
    for (let i = 0; i < menuDesktopSubSectionLink.length; i += 1) {
      menuDesktopSubSectionLink[i].classList.remove(activeClassMenuDesktopSectionLink);
    }

    for (let i = 0; i < menuDesktopSubSectionUnderContainer.length; i += 1) {
      if (menuDesktopSubSectionUnderContainer[i].classList.contains(activeClassMenuDesktopSubSectionUnderContainer)) {
        hideMenuSubsection(menuDesktopSubSectionUnderContainer[i]);
      }
    }
  }

  // Mettre le menu à l'état initial lors de sa fermeture
  function onCloseOverlayMenuDesktop() {
    hideAllMenuDesktopSubSection();
  }

  // Afficher un menu sub-section et appliquer la classe active au lien actif
  function showMenuDesktopSubSection(subSectionLinkActive, subSectionUnderContainerActive) {
    for (let i = 0; i < menuDesktopSubSectionLink.length; i += 1) {
      if (menuDesktopSubSectionLink[i] === subSectionLinkActive) {
        menuDesktopSubSectionLink[i].classList.add(activeClassMenuDesktopSectionLink);
      }
    }

    subSectionUnderContainerActive.classList.add(activeClassMenuDesktopSubSectionUnderContainer);
  }

  // Mettre le menu à l'état initial quand le menu change de section
  function onTriggerOverlayMenuDesktop() {
    hideAllMenuDesktopSubSection();

    // Remettre le scroll au top pour la section de gauche
    for (let i = 0; i < menuDesktopSectionLeft.length; i += 1) {
      menuDesktopSectionLeft[i].scrollTop = 0;
    }

    // Remettre le scroll au top pour la section de droite
    for (let i = 0; i < menuDesktopSectionRight.length; i += 1) {
      menuDesktopSectionRight[i].scrollTop = 0;
    }

    // Afficher la première sous-section d'une section seulement si cette section n'est pas déjà affiché
    const html = document.querySelector('html');
    const menuDesktopSectionActive = document.querySelector(`${targetMenuDesktopSection}[data-section="${html.dataset.section}"]`);
    const menuDesktopFirstSectionLink = menuDesktopSectionActive.querySelector(targetMenuDesktopSectionLink);
    const menuDesktopFirstSubSectionUnderContainer = document.querySelector(`${targetMenuDesktopSubSectionUnderContainer}[data-sub-section="${menuDesktopFirstSectionLink.dataset.subSection}"]`);

    showMenuDesktopSubSection(menuDesktopFirstSectionLink, menuDesktopFirstSubSectionUnderContainer);
  }

  // Gérer l'affichage/fermeture du menu sub-section
  function onChangeMenuDesktopSubSection(e) {
    const menuDesktopSubSectionLinkActive = e.currentTarget;
    const menuDesktopSubSectionUnderContainerActive = document.querySelector(`${targetMenuDesktopSubSectionUnderContainer}[data-sub-section="${menuDesktopSubSectionLinkActive.dataset.subSection}"]`);

    // Si le lien menuDesktopSubSectionLink n'est pas déjà actif
    if (!menuDesktopSubSectionLinkActive.classList.contains(activeClassMenuDesktopSectionLink)) {
      hideAllMenuDesktopSubSection();
      showMenuDesktopSubSection(menuDesktopSubSectionLinkActive, menuDesktopSubSectionUnderContainerActive);
    } else {
      hideAllMenuDesktopSubSection();
    }
  }

  OBSERVER.add({
    name: 'overlayMenuDesktop',
    events: 'onCloseOverlayMenu-desktop',
    function: onCloseOverlayMenuDesktop,
  });
  OBSERVER.add({
    name: 'overlayMenuDesktop',
    events: 'onTriggerOverlayMenu-desktop',
    function: onTriggerOverlayMenuDesktop,
  });
  OBSERVER.add({
    name: 'overlayMenuDesktop',
    events: 'click',
    targets: targetMenuDesktopSectionLink,
    function: onChangeMenuDesktopSubSection,
  });
  OBSERVER.on('overlayMenuDesktop');
}
