import Overlay from '../vendor/overlay';
import addHeightAttributeToContainer from './addHeightAttributeToContainer';

// Fonction gérant l'overlay du popup
export default function overlayPopup() {
  const overlay = new Overlay({
    name: 'popup',
    events: {
      open: true,
      closeComplete: true,
    },
    click: {
      buttons: {
        close: '.js-close-overlay-popup',
      },
    },
    timeout: {
      delay: 2000,
    },
    options: {
      speed: 800,
    },
  });

  overlay.init();

  // Fonction permettant de donner un attribut height au container de l'overlay (mettre events open et closeComplete à true)
  addHeightAttributeToContainer(overlay, 'Popup', '[data-overlay-container="popup"]');
}
