import Overlay from '../vendor/overlay';

// Fonction gérant l'overlay organisme
export default function overlayOrganization() {
  const overlay = new Overlay({
    name: 'organization',
    click: {
      buttons: {
        open: '.js-open-overlay-organization',
        close: '.js-close-overlay-organization',
      },
    },
    options: {
      speed: 800,
    },
  });

  overlay.init();

  return overlay;
}
