import Swiper from 'swiper/bundle';

// Fonction gérant le swiper de filtre
export default function swiperHomeDatesToRemember() {
  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper('[data-swiper="filter"]', {
    slidesPerView: 'auto',
    spaceBetween: 5,
    threshold: 10,
    speed: 300,
    freeMode: true,
    mousewheel: {
      forceToAxis: true,
      sensitivity: 0.5,
    },
    navigation: {
      prevEl: '[data-swiper-prev="filter"]',
      nextEl: '[data-swiper-next="filter"]',
      disabledClass: 'is-disabled',
    },
  });
}
