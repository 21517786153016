import Swiper from 'swiper/bundle';

// Fonction gérant le swiper des dates à retenir dans la page d'accueil
export default function swiperHomeDatesToRemember() {
  const swiperNavigationElements = document.querySelectorAll('.js-home-dates-to-remember-swiper-navigation-element');

  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper('[data-swiper="home-dates-to-remember"]', {
    slidesPerView: 'auto',
    spaceBetween: 20,
    threshold: 10,
    speed: 300,
    freeMode: true,
    mousewheel: {
      forceToAxis: true,
      sensitivity: 0.5,
    },
    navigation: {
      prevEl: '[data-swiper-prev="home-dates-to-remember"]',
      nextEl: '[data-swiper-next="home-dates-to-remember"]',
      disabledClass: 'is-disabled',
    },
    breakpoints: {
      801: {
        spaceBetween: 30,
      },
    },
    on: {
      lock() {
        swiperNavigationElements.forEach((swiperNavigation) => {
          // eslint-disable-next-line no-param-reassign
          swiperNavigation.style.display = 'none';
        });
      },
      unlock() {
        swiperNavigationElements.forEach((swiperNavigation) => {
          // eslint-disable-next-line no-param-reassign
          swiperNavigation.style.display = 'block';
        });
      },
    },
  });
}
