/**
 * Affiche le widget Jobillico.
 */
function showWidgetJobillico() {
  document.querySelector('#jobillico-widget').style.display = 'block';
}

/**
 * Affiche un message qui dit qu'il n'y a pas d'emploi.
 */
function displayNoJobsMessage() {
  // On enlève le message d'erreur de Jobillico
  const zone = document.querySelector('#jobillico-widget');
  zone.innerHTML = '';

  // On crée le message
  const text = document.createElement('p');
  text.appendChild(document.createTextNode('Il n’y a pas d’offre d’emploi disponible pour le moment.'));
  text.appendChild(document.createElement('br'));
  text.appendChild(document.createTextNode('Revenez nous voir.'));

  // On le met dans un div de contenu dynamique (pour avoir le style du <p>)
  const div = document.createElement('div');
  div.classList.add('s-cms-content');
  div.appendChild(text);

  // On met tout ça dans la zone
  zone.appendChild(div);

  // On enlève le display none pour voir le message
  showWidgetJobillico();
}

/**
 * Exécute une fonction de rappel lorsque le widget Jobillico est prêt.
 * Si le widget Jobillico n'est pas encore créé, la vérification est effectuée périodiquement pendant 10 secondes jusqu'à ce qu'il soit prêt.
 * @param {Function} callback - La fonction de rappel à exécuter lorsque le widget Jobillico est prêt.
 */
function executeWhenWidgetJobillicoReady(callback) {
  const maxTime = 10000; // 10 secondes
  const interval = 100; // Intervalle entre chaque tentative
  let elapsedTime = 0;

  const intervalId = setInterval(() => {
    elapsedTime += interval;

    if (elapsedTime >= maxTime || document.querySelector('#jobillico-widget #jobi-jobs-list')) {
      clearInterval(intervalId);

      if (document.querySelector('#jobillico-widget #jobi-jobs-list')) {
        callback();
      } else {
        console.warn("Le widget Jobillico n'a pas pu être trouvé après 10 secondes.");
        displayNoJobsMessage();
      }
    }
  }, interval);
}

/**
 * Extrait et retourne le statut d'un emploi à partir d'une chaîne de texte.
 * Si le format commence par "Statut de l'emploi", renvoie le contenu après les deux-points (:).
 * Sinon, renvoie la chaîne complète après avoir nettoyé les espaces superflus.
 * @param {string} jobText - La chaîne de texte contenant le statut de l'emploi.
 * @returns {string} Le statut d'emploi extrait ou la chaîne complète.
 */
function extractJobStatus(jobText) {
  const regex = /^ *Statut de l'emploi *: *(.*)/i;
  const match = jobText.match(regex);

  if (match && match[1]) {
    return match[1].trim();
  }

  return jobText.trim();
}

/**
 * Met à jour le HTML d'une seule carte d'emploi avec le titre d'emploi donné.
 * @param {HTMLElement} container - L'élément conteneur de la carte d'emploi.
 * @param {string} title - Le titre de l'emploi.
 * @param {string} status - Le statut de l'emploi.
 */
function updateJobCardHTML(container, title, status) {
  const containerLink = container.querySelector('a');
  const jobCardHTML = `
    <div class="c-job-card__content">
      <span class="c-job-card__surtitle">${status}</span>
      <h3 class="c-job-card__title">${title}</h3>
    </div>
    <div class="c-job-card__svg-container">
      <svg class="c-job-card__svg" role="img">
        <use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols-box.svg#ico-arrow"></use>
      </svg>
    </div>
  `;

  // Ajoute la classe "c-job-card" à l'élément lien pour appliquer le style de carte d'emploi
  // Enlever aussi les styles par défaut dans l'attribut style de la carte d'emploi
  containerLink.classList.add('c-job-card');
  containerLink.removeAttribute('style');

  // Remplace le contenu du conteneur de la carte d'emploi par le HTML personnalisé
  containerLink.innerHTML = jobCardHTML;
}

/**
 * Met à jour le texte des boutons de retour à la liste des emplois en supprimant les deux premiers guillemets (<<).
 * @param {HTMLElement} container - L'élément conteneur qui contient les boutons de retour.
 */
function updateButtonsBackToList(container) {
  const buttonsBackToList = container.querySelectorAll('.expand p:first-child a, .expand p:last-child a');

  buttonsBackToList.forEach((button) => {
    // eslint-disable-next-line no-param-reassign
    button.innerText = button.innerText.replace('<< ', '');
  });
}

/**
 * Met à jour la structure HTML de la liste d'emplois fournie par le widget Jobillico.
 */
function personnalizeJobsListHTML() {
  // Sélectionne les éléments individuels d'emplois
  const jobItems = document.querySelectorAll('#jobi-jobs-list > li');

  // Parcours chaque élément d'emploi et personnalise son HTML
  jobItems.forEach((jobItem) => {
    const jobTitle = jobItem.querySelector('a').innerText;
    let jobStatus = '';
    if (jobItem.querySelector('.jobstatus') !== null) {
      jobStatus = extractJobStatus(jobItem.querySelector('.jobstatus').innerText);
    }
    updateJobCardHTML(jobItem, jobTitle, jobStatus);
    updateButtonsBackToList(jobItem);
  });
}

/**
 * Fonction de rappel pour le clic sur les liens de pagination.
 */
function handlePaginationLinkClick() {
  executeWhenWidgetJobillicoReady(() => {
    personnalizeJobsListHTML();
    // eslint-disable-next-line no-use-before-define
    addEventClickOnPaginationLink();
  });
}

/**
 * Ajoute un gestionnaire d'événement de clic sur les liens de pagination du widget Jobillico.
 */
function addEventClickOnPaginationLink() {
  const paginationLinks = document.querySelectorAll('a[onclick^="initWidget"]');

  if (paginationLinks.length > 0) {
    paginationLinks.forEach((link) => {
      link.addEventListener('click', handlePaginationLinkClick);
    });
  }
}

/**
 * Personnalise le widget Jobillico en mettant à jour la structure HTML de la liste d'emplois.
 */
export default function personnalizeWidgetJobillico() {
  executeWhenWidgetJobillicoReady(() => {
    personnalizeJobsListHTML();
    showWidgetJobillico();
    addEventClickOnPaginationLink();
  });
}
