import $ from 'jquery';
import validationMessages from './validationMessages';
import validation from './validations';
// eslint-disable-next-line import/no-relative-packages
import AddressGoogle from '../../../../../plugins/blanko/forms/assets/js/functions/addressGoogle';

// La validation du formulaire de contact
export default function formContact() {
  // eslint-disable-next-line no-unused-vars
  const adresseGoogle = new AddressGoogle(document.querySelector('.localisation_field'));

  // eslint-disable-next-line prefer-arrow-callback
  $.validator.addMethod('validateMultiFiles', function multiFiles(element) {
    let valid = true;
    if (!element) {
      valid = !document.querySelector(':not(#form-contact-documents).js-multiple-files-field-button-input:not(.valid)');
    }
    return valid;
  }, 'Au moins un fichier est invalide, supprimer le avant de soumettre');

  const m = validationMessages();

  const rules = {
    object: { required: true, maxlength: 300 },
    firstname: { required: true },
    lastname: { required: true },
    address: { required: true },
    phone: { required: true, minlength: 12 },
    email: { required: true, email: true, courriel: true },
    description: { required: true, maxlength: 300 },
    'documents[]': { accept: 'jpg,jpeg,png,pdf,doc,docx,xls,xlsx', validateMultiFiles: true, filesize: 7340032 },
    consent: { required: true },
  };
  const messages = {
    object: { required: m.required, maxlength: m.charactersCount300 },
    firstname: { required: m.required },
    lastname: { required: m.required },
    address: { required: m.required },
    phone: { required: m.required, minlength: m.phone },
    email: { required: m.required, email: m.email, courriel: m.email },
    description: { required: m.required, maxlength: m.charactersCount300 },
    'documents[]': { accept: m.acceptMultiFile, filesize: 'La taille maximale du fichier doit être 7MB' },
    consent: { required: m.required },
  };

  validation('#form-contact', '#form-contact-submit', rules, messages);
}
