import { OBSERVER } from '../plugins';

// Permet de supprimer le contenu d'un input
export default function clearInput() {
  const onClick = (e) => {
    const input = e.currentTarget.parentNode.querySelector('.js-input-to-clear');
    input.value = '';
    input.focus();
    input.parentElement.dataset.inputvalue = '';
    input.classList.remove('valid');
  };

  OBSERVER.add({
    name: 'clearInput',
    events: 'click',
    targets: '.js-clear-input',
    function: onClick,
  });
  OBSERVER.on('clearInput');
}
