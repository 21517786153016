import '../scss/style.scss';
import Namespace from './namespaces';
import tailSelect from './form/tailSelect';
import changeSelect from './form/changeSelect';

document.addEventListener('DOMContentLoaded', () => {
  const namespaceAttribute = 'data-namespace';
  const namespaceSelector = `[${namespaceAttribute}]`;

  const namespaceElement = document.querySelector(namespaceSelector);
  const namespaceAttributeValue = namespaceElement.getAttribute(namespaceAttribute);
  const lowercasedNamespaceAttributeValue = namespaceAttributeValue.charAt(0).toLowerCase() + namespaceAttributeValue.slice(1);

  window.namespace = new Namespace();
  if (window.namespace[lowercasedNamespaceAttributeValue]) {
    window.namespace[lowercasedNamespaceAttributeValue]();
  } else {
    console.warn(`[Namespace] : Votre page tente d'appeler une vue de la classe Namespace qui n'a pas été déclarée. La vue standard sera appelée par défaut. Si vous souhaitez avoir une vue spécifique pour le namespace de la page, assurez-vous d'ajouter une méthode nommée ${lowercasedNamespaceAttributeValue}() dans le fichier namespace.js.`);
    window.namespace.standard();
  }
});

document.addEventListener('DOMContentLoaded', () => {
  tailSelect();

  if (document.querySelector('#form-directory-of-organizations-sort-a-to-z') !== null) {
    changeSelect('#form-directory-of-organizations-sort-a-to-z');
  }
  if (document.querySelector('#form-directory-of-organizations-categories') !== null) {
    changeSelect('#form-directory-of-organizations-categories');
  }

  if (document.querySelector('#form-directory-of-regulations-and-zoning-sort-a-to-z') !== null) {
    changeSelect('#form-directory-of-regulations-and-zoning-sort-a-to-z');
  }

  if (document.querySelector('#form-directory-of-regulations-and-zoning-categories') !== null) {
    changeSelect('#form-directory-of-regulations-and-zoning-categories');
  }

  if (document.querySelector('#form-registration-garage-sale-period') !== null) {
    changeSelect('#form-registration-garage-sale-period');
  }
});
