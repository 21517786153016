import Swiper from 'swiper/bundle';

// Fonction gérant le swiper des actualités
export default function swiperPublications() {
  const swiperContainers = document.querySelectorAll('.js-component-publications-swiper');

  swiperContainers.forEach((element) => {
    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(element.querySelector('[data-swiper="publications"]'), {
      slidesPerView: 'auto',
      spaceBetween: 30,
      threshold: 10,
      speed: 700,
      freeMode: false,
      mousewheel: {
        forceToAxis: true,
        sensitivity: 0.5,
      },
      navigation: {
        prevEl: element.querySelector('[data-swiper-prev="publications"]'),
        nextEl: element.querySelector('[data-swiper-next="publications"]'),
        disabledClass: 'is-disabled',
      },
      breakpoints: {
        1300: {
          spaceBetween: 28,
        },
        1200: {
          spaceBetween: 26,
        },
        1000: {
          spaceBetween: 24,
        },
        800: {
          spaceBetween: 23,
        },
        600: {
          spaceBetween: 22,
        },
      },
    });
  });
}
