// Permet d'activer le scrollfire de tous les éléments qui sont présents dans le viewport lors du load de page
export default function activeScrollfireInViewport() {
  const elementsScrollfire = document.querySelectorAll('.js-scrollfire');

  function isElementInViewport(element, offsetTop) {
    const rect = element.getBoundingClientRect();

    const top = (rect.top - offsetTop) <= (window.innerHeight || document.documentElement.clientHeight);
    const right = rect.right >= 0;
    const bottom = rect.bottom >= 0;
    const left = rect.left <= (window.innerWidth || document.documentElement.clientWidth);

    return top && right && bottom && left;
  }

  elementsScrollfire.forEach((elementScrollfire) => {
    const matrix = window.getComputedStyle(elementScrollfire, null).getPropertyValue('transform');

    if (matrix !== 'none') {
      const stringArrayMatrix = matrix.replace('matrix(', '').replace(')', '');
      const arrayMatrix = JSON.parse(`[${stringArrayMatrix}]`);
      const scrollfireOffset = arrayMatrix[5];

      if (isElementInViewport(elementScrollfire, scrollfireOffset)) {
        elementScrollfire.setAttribute('data-fire', '');
      }
    }
  });
}
