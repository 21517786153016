import validationMessages from './validationMessages';
import validation from './validations';

// La validation du formulaire d'exemple
export default function formExample() {
  const m = validationMessages();

  const rules = {
    select: { required: true },
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, email: true, courriel: true },
    phone: { required: true, minlength: 12 },
    'files[]': { accept: false },
    message: { required: true },
    cv: { required: true },
    'radioInline[]': { required: true },
    'checkbox[]': { required: true },
  };
  const messages = {
    select: { required: m.required },
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, email: m.email, courriel: m.email },
    phone: { required: m.required, minlength: m.phone },
    message: { required: m.required },
    cv: { required: m.required },
    'radioInline[]': { required: m.required },
    'checkbox[]': { required: m.required },
  };

  validation('#form-example', '#form-example-submit', rules, messages);
}
