// Retire tous les effets d'over sur le mobile
export default function removeHoverOnMobile() {
  try {
    // prevent exception on browsers not supporting DOM styleSheets properly
    let k;
    const { styleSheets } = document;
    const styleSheetsLength = styleSheets.length;
    for (k = 0; k < styleSheetsLength; k += 1) {
      // for (const si in document.styleSheets) {
      const styleSheet = styleSheets[k];
      if (styleSheet.rules) {
        for (let ri = styleSheet.rules.length - 1; ri >= 0; ri -= 1) {
          if (styleSheet.rules[ri].selectorText) {
            if (styleSheet.rules[ri].selectorText.match(':hover')) {
              const { selectorText } = styleSheet.rules[ri];
              const araySelectorText = selectorText.split(',');
              let newSelectorText = '';

              // Garder slm les selectorText no :hover
              for (let i = 0; i < araySelectorText.length; i += 1) {
                if (!araySelectorText[i].match(':hover')) {
                  newSelectorText += newSelectorText !== '' ? `, ${araySelectorText[i]}` : ` ${araySelectorText[i]}`;
                }
              }

              // Enlver la règle si la seule classe est un :hover
              if (newSelectorText === '') {
                styleSheet.deleteRule(ri);
              } else {
                styleSheet.rules[ri].selectorText = newSelectorText;
              }
            }
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}
