import CustomizedButtonsList from '../classes/CustomizedButtonsList';

export default function customizedButtons() {
  const DEFAULT_SELECTOR = '[data-customized-button="default"]';
  const DYNAMIC_LINK_SELECTOR = '.js-cms-content a:not(.primary-button):not(.secondary-button):not([target=_blank]):not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"]):not(.docs)';
  const DYNAMIC_BUTTON_SELECTOR = '.js-cms-content :where(a.primary-button, a.secondary-button):not([target=_blank]):not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])';
  const DYNAMIC_EXTERNAL_LINK_AND_BUTTON_SELECTOR = '.js-cms-content a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"]):not(.docs)';
  const DYNAMIC_DOWNLOAD_LINK_AND_BUTTON_SELECTOR = '.js-cms-content a[href$=".pdf"]:not(.docs), .js-cms-content a[href$=".doc"]:not(.docs), .js-cms-content a[href$=".zip"]:not(.docs)';
  const DYNAMIC_EXTERNAL_LINK_DOCS_SELECTOR = '.js-cms-content a.docs:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])';
  const DYNAMIC_DOWNLOAD_LINK_DOCS_SELECTOR = '.js-cms-content a.docs[href$=".pdf"], .js-cms-content a.docs[href$=".doc"], .js-cms-content a.docs[href$=".zip"]';

  const LISTS = [{
    selector: DEFAULT_SELECTOR,
  }, {
    selector: DYNAMIC_LINK_SELECTOR,
  }, {
    selector: DYNAMIC_BUTTON_SELECTOR,
  }, {
    selector: DYNAMIC_EXTERNAL_LINK_AND_BUTTON_SELECTOR,
    options: {
      svgName: 'external',
    },
  }, {
    selector: DYNAMIC_DOWNLOAD_LINK_AND_BUTTON_SELECTOR,
    options: {
      svgName: 'download',
    },
  }, {
    selector: DYNAMIC_EXTERNAL_LINK_DOCS_SELECTOR,
    options: {
      svgName: 'external',
      svgWithSpan: true,
    },
  }, {
    selector: DYNAMIC_DOWNLOAD_LINK_DOCS_SELECTOR,
    options: {
      svgName: 'download',
      svgWithSpan: true,
    },
  }];

  LISTS.forEach((list) => {
    const CUSTOMIZED_BUTTONS_LIST = new CustomizedButtonsList(list.selector, list.options);
    CUSTOMIZED_BUTTONS_LIST.init();
  });
}
