import { OBSERVER } from '../plugins';

// Permet d'agrandir la taille de la police
export default function zoomFontSize() {
  const html = document.querySelector('html');
  const buttonsOption = document.querySelectorAll('.js-zoom-font-size-option');
  let zoom = localStorage.getItem('zoom');

  // Initialisation par défaut de la font-size et de son animation
  if (!zoom) {
    zoom = '62.5';
    localStorage.setItem('zoom', zoom);
    html.style.fontSize = zoom;
    html.dataset.zoomFontSize = zoom;
  } else {
    html.style.fontSize = `${zoom}%`;
    html.dataset.zoomFontSize = zoom;
  }
  setTimeout(() => { html.style.transition = 'font-size 300ms'; }, 300);

  // Mettre le bouton qui correspond au zoom actif en mode actif
  buttonsOption.forEach((buttonOption) => {
    if (`${buttonOption.dataset.zoom}` === zoom) {
      buttonOption.classList.add('is-active');
    }
  });

  // Changer la font-size au clic des options
  const setZoom = (e) => {
    localStorage.setItem('zoom', e.target.dataset.zoom);
    buttonsOption.forEach((buttonOption) => {
      buttonOption.classList.remove('is-active');
    });
    e.target.classList.add('is-active');
    html.style.fontSize = `${e.target.dataset.zoom}%`;
    html.dataset.zoomFontSize = e.target.dataset.zoom;
  };

  OBSERVER.add({
    name: 'zoomFontSize',
    events: 'click',
    targets: '.js-zoom-font-size-option',
    function: setZoom,
  });
  OBSERVER.on('zoomFontSize');
}
