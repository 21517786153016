import validationMessages from './validationMessages';
import validation from './validations';

// La validation du formulaire d'inscription à l'infolettre
export default function formNewsletter() {
  const m = validationMessages();

  const rules = {
    email: { required: true, email: true, courriel: true },
    'email-confirmation': {
      required: true, email: true, courriel: true, equalTo: '#form-newsletter-email',
    },
  };
  const messages = {
    email: { required: m.required, email: m.email, courriel: m.email },
    'email-confirmation': {
      required: m.required, email: m.email, courriel: m.email, equalTo: m.emailConfirmation,
    },
  };

  validation('#form-newsletter', '#form-newsletter-submit', rules, messages);
}
