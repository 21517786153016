// Ajouter des marges pour les <p> qui contiennent des boutons
export default function adjustButtonsMargins() {
  let i;
  const buttons = document.querySelectorAll('.js-cms-content p > a.primary-button, .js-cms-content p > a.secondary-button');
  const buttonsLength = buttons.length;

  for (i = 0; i < buttonsLength; i += 1) { buttons[i].parentNode.classList.add('buttons'); }

  const buttonsParagraph = document.querySelectorAll('.js-cms-content p.buttons');
  const buttonsParagraphLength = buttonsParagraph.length;

  for (i = 0; i < buttonsParagraphLength; i += 1) {
    if (buttonsParagraph[i].previousElementSibling !== null) {
      if (!buttonsParagraph[i].previousElementSibling.classList.contains('buttons')) {
        buttonsParagraph[i].classList.add('buttons--first');
      }
    }
    if (buttonsParagraph[i].nextElementSibling !== null) {
      if (!buttonsParagraph[i].nextElementSibling.classList.contains('buttons')) {
        buttonsParagraph[i].classList.add('buttons--last');
      }
    }
  }
}
