import Overlay from '../vendor/overlay';
import Accordions from '../classes/Accordions';
import { OBSERVER } from '../plugins';

// Fonction gérant l'overlay du menu mobile
export default function overlayMenuMobile() {
  const overlay = new Overlay({
    name: 'menu-mobile',
    events: {
      open: true,
      close: true,
    },
    click: {
      buttons: {
        toggle: '.js-toggle-overlay-menu-mobile',
        close: 'a[href], .js-close-overlay-menu-mobile',
        switch: '.js-trigger-overlay-search',
      },
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
    },
  });

  overlay.init();

  // Initialiser les accordions
  const sideNavigationLevel1InOverlayMenuMobile = new Accordions({
    containerClass: 'js-accordions-side-navigation-level-1-in-overlay-menu-mobile',
    accordionClass: 'js-accordion-side-navigation-level-1-in-overlay-menu-mobile',
    toggleButtonClass: 'js-accordion-side-navigation-toggle-level-1-in-overlay-menu-mobile',
    contentClass: 'js-accordion-side-navigation-content-level-1-in-overlay-menu-mobile',
  });
  sideNavigationLevel1InOverlayMenuMobile.offset = 0;
  sideNavigationLevel1InOverlayMenuMobile.init();

  const sideNavigationLevel2InOverlayMenuMobile = new Accordions({
    containerClass: 'js-accordions-side-navigation-level-2-in-overlay-menu-mobile',
    accordionClass: 'js-accordion-side-navigation-level-2-in-overlay-menu-mobile',
    toggleButtonClass: 'js-accordion-side-navigation-toggle-level-2-in-overlay-menu-mobile:not([href])',
    contentClass: 'js-accordion-side-navigation-content-level-2-in-overlay-menu-mobile',
  });
  sideNavigationLevel2InOverlayMenuMobile.offset = 0;
  sideNavigationLevel2InOverlayMenuMobile.init();

  function onClose() {
    sideNavigationLevel1InOverlayMenuMobile.closeAll();
    sideNavigationLevel2InOverlayMenuMobile.closeAll();
  }

  function onOpen() {
    const overlayMenuMobileContentsScroll = document.querySelector('.js-overlay-menu-mobile-contents-scroll');

    overlayMenuMobileContentsScroll.scrollTop = 0;
  }

  OBSERVER.add({
    name: 'overlayMenuMobile',
    events: 'onOpenOverlayMenu-mobile',
    function: onOpen,
  });
  OBSERVER.add({
    name: 'overlayMenuMobile',
    events: 'onCloseOverlayMenu-mobile',
    function: onClose,
  });
  OBSERVER.on('overlayMenuMobile');
}
