import { OBSERVER } from '../plugins';

// Permet de donner la largeur exacte en mobile de 100vw
// Css -> width: calc(var(--vw, 1vw) * 100);
export default function hundredVW() {
  function setWidth() {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    const vw = (window.innerWidth - scrollbarWidth) * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }

  OBSERVER.add({
    name: 'hundredVW',
    events: 'resize load',
    targets: 'window',
    function: setWidth,
  });
  OBSERVER.on('hundredVW');
}
