import { OBSERVER } from '../plugins';

// Fonction permettant de donner un attribut height au container de l'overlay
export default function addHeightAttributeToContainer(classOverlay, nameEventOverlay, targetContainerOverlay) {
  const containerOverlay = document.querySelector(targetContainerOverlay);

  function onOpen() {
    containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
  }

  function onCloseComplete() {
    containerOverlay.style.height = null;
  }

  function onResize() {
    containerOverlay.style.height = null;
    containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
  }

  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: `onOpenOverlay${nameEventOverlay}`,
    function: onOpen,
  });
  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: `onCloseCompleteOverlay${nameEventOverlay}`,
    function: onCloseComplete,
  });
  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: 'resize',
    function: onResize,
  });
  OBSERVER.on(`overlay${nameEventOverlay}`);
}
