import { OBSERVER } from '../plugins';

// Permet de donner la hauteur exacte en mobile de 100vh
// Css -> height: calc(var(--vh, 1vh) * 100);
export default function hundredVH() {
  function setHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  OBSERVER.add({
    name: 'hundredVH',
    events: 'resize load',
    targets: 'window',
    function: setHeight,
  });
  OBSERVER.on('hundredVH');
}
