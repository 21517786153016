import validationMessages from './validationMessages';
import validation from './validations';
// eslint-disable-next-line import/no-relative-packages
import AddressGoogle from '../../../../../plugins/blanko/forms/assets/js/functions/addressGoogle';

// La validation du formulaire de ventes-débarras
export default function formRegistrationGarageSale() {
  // eslint-disable-next-line no-unused-vars
  const adresseGoogle = new AddressGoogle(document.querySelector('.localisation_field'));

  const m = validationMessages();

  const rules = {
    firstname: { required: true },
    lastname: { required: true },
    phone: { minlength: 12 },
    email: { required: true, email: true, courriel: true },
    'citizen-card-number': { required: true, minlength: 6, maxlength: 6 },
    address: { required: true },
    period: { required: true },
    horaire: { maxlength: 300 },
    'datesvente[]': { required: true },
    'cats[]': { required: true },
    'cat-other': { maxlength: 100 },
    description: { maxlength: 300 },
    'public-display-consent[]': { required: true },
    'respect-garage-sale-regulations[]': { required: true },
  };
  const messages = {
    firstname: { required: m.required },
    lastname: { required: m.required },
    phone: { minlength: m.phone },
    email: { required: m.required, email: m.email, courriel: m.email },
    'citizen-card-number': { required: m.required, minlength: 'Le numéro de carte doit comporter 6 chiffres', maxlength: 'Le numéro de carte doit comporter 6 chiffres' },
    address: { required: m.required },
    period: { required: m.select },
    horaire: { maxlength: m.charactersCount300 },
    'datesvente[]': { required: m.required },
    'cats[]': { required: m.required },
    'cat-other': { maxlength: 'La catégorie ne doit pas dépasser 100 caractères' },
    description: { maxlength: m.charactersCount300 },
    'public-display-consent[]': { required: m.required },
    'respect-garage-sale-regulations[]': { required: m.required },
  };

  validation('#form-registration-garage-sale', '#form-registration-garage-sale-submit', rules, messages);
}
