import $ from 'jquery';
import { OBSERVER } from '../plugins';

export default function toggleFieldContainer() {
  const OPEN = (container) => {
    const CONTAINER = container;
    $(CONTAINER).fadeIn(500);
    CONTAINER.classList.remove('is-hidden');
  };

  const CLOSE = (container) => {
    const CONTAINER = container;
    const inputNodes = CONTAINER.getElementsByTagName('INPUT');

    for (let i = 0; i < inputNodes.length; i += 1) {
      const inputNode = inputNodes[i];
      inputNode.value = '';
    }

    $(CONTAINER).fadeOut(500);
    CONTAINER.classList.add('is-hidden');
  };

  const TOGGLE_FIELD_CONTAINER_VISIBILITY = (e) => {
    const BUTTON = e.target;
    // eslint-disable-next-line max-len
    const BUTTON_ATTRIBUTE = BUTTON.tagName === 'SELECT' ? BUTTON.querySelector(`[value="${BUTTON.value}"]`).getAttribute('data-field-container-controller') : BUTTON.getAttribute('data-field-container-controller');
    const CONTAINERS = document.querySelectorAll(`[data-field-container=${BUTTON_ATTRIBUTE}]`);

    CONTAINERS.forEach((container) => {
      const CONTAINER = container;
      const IS_HIDDEN = CONTAINER.classList.contains('is-hidden');

      if (IS_HIDDEN) {
        OPEN(CONTAINER);
      } else {
        CLOSE(CONTAINER);
      }
    });
  };

  const BUTTONS = document.querySelectorAll('[data-field-container-controller]:not(.active)');
  BUTTONS.forEach((button) => {
    const BUTTON = button;
    BUTTON.classList.add('active');
    const NAME = BUTTON.getAttribute('name');
    OBSERVER.add({
      name: 'toggleFieldContainer',
      events: 'change',
      targets: `[name="${NAME}"]`,
      function: TOGGLE_FIELD_CONTAINER_VISIBILITY,
    });
  });

  OBSERVER.on('toggleFieldContainer');
}
