import { OBSERVER } from '../plugins';

export default function limitedCheckboxLists(selector, disabler) {
  const onChange = (e) => {
    // Read Choice limit
    const limitedCheckbox = e.currentTarget;
    const limitedCheckboxContainer = limitedCheckbox.closest(selector);

    let limit = 0;
    if (limitedCheckboxContainer.hasAttribute('data-choice-limit')) {
      limit = limitedCheckboxContainer.dataset.choiceLimit;
    }

    // No Limit Provided.
    if (!limit) return;

    const checkedCheckbox = limitedCheckboxContainer.querySelectorAll('input[type="checkbox"]:checked');
    const notCheckedCheckbox = limitedCheckboxContainer.querySelectorAll('input[type="checkbox"]:not(:checked)');
    const disabledCheckboxLabel = limitedCheckboxContainer.querySelectorAll(`.${disabler}`);

    if (checkedCheckbox.length >= limit) {
      // Disable all not check
      for (let i = 0; i < notCheckedCheckbox.length; i += 1) {
        notCheckedCheckbox[i].closest('label').classList.add(disabler);
      }
    } else {
      // Enable all disabled
      for (let i = 0; i < disabledCheckboxLabel.length; i += 1) {
        disabledCheckboxLabel[i].classList.remove(disabler);
      }
    }
  };

  OBSERVER.add({
    name: 'limitedCheckboxLists',
    events: 'change',
    targets: `${selector} input[type="checkbox"]`,
    function: onChange,
  });
  OBSERVER.on('limitedCheckboxLists');
}
