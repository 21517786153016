import $ from 'jquery';
import 'jquery-mask-plugin';

// Le formatage du code postal
export default function maskPostalCode() {
  $('.js-mask-postal-code').mask('Z0Z 0Z0', {
    translation: {
      Z: {
        pattern: /[A-Z, a-z]/,
      },
    },
  });

  // Forcer les majuscules
  $('.js-mask-postal-code').keyup(function keyup() {
    $(this).val($(this).val().toUpperCase());
  });
}
