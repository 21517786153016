import { OBSERVER } from '../plugins';

// Permet de charger davantage de cartes de règlement
export default function loadMoreRegulationCard() {
  const onClick = () => {
    const regulationCardsNotShow = document.querySelectorAll('.js-regulation-card:not(.is-show)');
    const buttonLoadMoreContainer = document.querySelector('.js-button-load-more-container');

    for (let i = 0; i < 10 && i < regulationCardsNotShow.length; i += 1) {
      regulationCardsNotShow[i].classList.add('is-show');
    }

    // Vérifier s'il reste des cards à afficher
    const remainingRegulationCardsNotShow = document.querySelectorAll('.js-regulation-card:not(.is-show)');

    // Si ce n'est pas le cas, cachez ou supprimez le bouton "voir plus"
    if (remainingRegulationCardsNotShow.length === 0) {
      buttonLoadMoreContainer.style.display = 'none';
    }
  };

  OBSERVER.add({
    name: 'loadMoreRegulationCard',
    events: 'click',
    targets: '.js-button-load-more',
    function: onClick,
  });
  OBSERVER.on('loadMoreRegulationCard');
}

// Permet de mettre à jour le chargement de cartes de règlement afin de réinitialiser l'écouteur d'événement
export function updateLoadMoreRegulationCard() {
  loadMoreRegulationCard();
}
