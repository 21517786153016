import { Datepicker } from 'vanillajs-datepicker';
import isMobile from '@blankonumerique/blanko-scripts/is-mobile';

// Initialisation du champ de calendrier
export default function calendarInput() {
  if (!isMobile()) {
    const datepickersInput = document.querySelectorAll('.js-input-field-calendar .js-input-field-input');
    const datepickersDropdown = [];

    Datepicker.locales.fr = {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
      monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
      today: "Aujourd'hui",
      monthsTitle: 'Mois',
      clear: 'Effacer',
      weekStart: 0,
      format: 'dd/mm/yyyy',
    };

    for (let i = 0; i < datepickersInput.length; i += 1) {
      datepickersDropdown.push(
        new Datepicker(datepickersInput[i], {
          language: document.querySelector('html').lang,
          prevArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-chevron"></use></svg>`,
          nextArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-chevron"></use></svg>`,
          format: 'dd MM yyyy',
          todayHighlight: true,
          autohide: true,
          beforeShowDay: (date) => ({
            content: `
              <span class="day-square-container-parent">
                <span class="day-square-container">
                  <span class="day-square">${date.getDate()}</span>
                </span>
              </span>`,
          }),
        }),
      );
    }
  } else {
    document.querySelectorAll('.js-input-field-calendar .js-input-field-input').forEach(
      (element) => element.setAttribute('type', 'date'),
    );
  }
}

window.calendarInput = calendarInput; // Pour que les repeaters dans les forms
