import isMobile from '@blankonumerique/blanko-scripts/is-mobile';

// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export default function tailSelect() {
  const themePath = window.config.theme_path;

  if (!isMobile()) {
    // eslint-disable-next-line no-undef
    tail.select('select', { animate: false });
  }

  // Ajouter les images dans les options du Tail Select
  const selectBoxes = document.querySelectorAll('.tail-select');
  const selectBoxesLength = selectBoxes.length;

  // Looper dans chaques boites de sélections Tail Select
  for (let i = 0; i < selectBoxesLength; i += 1) {
    // Ajouter l'icone en symbole
    const spanElem = document.createElement('span');
    const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    useElem.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'xlink:href',
      `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-chevron`,
    );

    svgElem.appendChild(useElem);
    spanElem.appendChild(svgElem);
    spanElem.classList.add('svg-container');
    selectBoxes[i].appendChild(spanElem);
  }
}

window.tailSelect = tailSelect; // Pour que les repeaters dans les forms
