import { OBSERVER } from '../plugins';

// Permet de trigger un changement sur un select
export default function changeSelect(select) {
  const DROPDOWN = document.querySelector(select);
  const CUSTOM_DROPDOWN = DROPDOWN.parentElement.querySelector('.tail-select');

  function triggerChange() {
    const e = new Event('change', { bubbles: true });
    DROPDOWN.dispatchEvent(e);
  }

  if (DROPDOWN && CUSTOM_DROPDOWN) {
    const CUSTOM_OPTIONS = CUSTOM_DROPDOWN.querySelectorAll('.dropdown-option');

    OBSERVER.add({
      name: 'changeSelect',
      events: 'click',
      targets: CUSTOM_OPTIONS,
      function: triggerChange,
    });
    OBSERVER.on('changeSelect');
  }
}
