import createCookie from '../functions/createCookie';
import getCookie from '../functions/getCookie';

// Fonction gérant l'overlay cookies (overlay custom)
// On n'utilise pas l'Observer et la classe Overlay pour assurer la compatibilité avec d'anciens sites.
export default function overlayCookies() {
  if (getCookie('acceptCookies') !== 'true') {
    const html = document.querySelector('html');
    const overlay = document.querySelector('[data-overlay="cookies"]');
    const closeBtn = document.querySelector('.js-close-overlay-cookies');
    const acceptBtn = document.querySelector('.js-overlay-cookies-button-accept');
    const openDelay = 1000;
    const closeDuration = 800;

    // Fonction pour ouvrir l'overlay
    const open = () => {
      html.classList.add(`show-overlay-${overlay.dataset.overlay}`);
    };

    // Fonction pour fermer l'overlay
    const close = () => {
      html.classList.add(`closing-overlay-${overlay.dataset.overlay}`);

      setTimeout(() => {
        html.classList.remove(`show-overlay-${overlay.dataset.overlay}`, `closing-overlay-${overlay.dataset.overlay}`);
      }, closeDuration);
    };

    // Fonction pour accepter les cookies et créer le cookie 'acceptCookies' avec une expiration d'un an (365 jours)
    const acceptCookies = () => {
      createCookie('acceptCookies', 'true', 365);
    };

    // Ouvre l'overlay lors du chargement de la page avec un délai
    setTimeout(open, openDelay);

    // Event listener pour le bouton de fermeture et d'acceptation
    closeBtn.addEventListener('click', close);
    acceptBtn.addEventListener('click', acceptCookies);
  }
}
