import $ from 'jquery';
import 'eonasdan-bootstrap-datetimepicker';
import isMobile from '@blankonumerique/blanko-scripts/is-mobile';

// Initialisation du champ de timepicker
export default function timePicker() {
  if (!isMobile()) {
    $('.js-input-field-time .js-input-field-input').datetimepicker({
      format: 'HH:mm',
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
      },
    });
  }
}
