// Fonction contenant tous les messages
export default function validationMessages() {
  let messages;

  const lang = document.querySelector('html').getAttribute('lang');

  if (lang === 'en') {
    messages = {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      acceptMultiFile: 'The file must be a pdf document, png, jpg or jpeg image.',
      file: 'Please provide a adequate file.',
      charactersCount300: 'The text should not exceed 300 characters.',
      emailConfirmation: 'This field must match the provided email address.',
    };
  } else {
    messages = {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      acceptMultiFile: 'Le fichier doit être un document pdf, png, jpg ou jpeg.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.',
      charactersCount300: 'Le texte ne doit pas dépasser les 300 caractères.',
      emailConfirmation: 'Ce champ doit correspondre à l’adresse électronique fournie.',
    };
  }

  return messages;
}
