import validationMessages from './validationMessages';
import validation from './validations';

// La validation du formulaire d'emplois
export default function formJobs() {
  const m = validationMessages();

  const rules = {
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, email: true, courriel: true },
    phone: { required: true, minlength: 12 },
    cv: { accept: 'pdf', filesize: 5242880, required: true },
    letter: { accept: 'pdf', filesize: 5242880 },
  };
  const messages = {
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, email: m.email, courriel: m.email },
    phone: { required: m.required, minlength: m.phone },
    cv: { accept: m.accept, required: m.required },
    letter: { accept: m.accept },
  };

  validation('#form-jobs', '#form-jobs-submit', rules, messages);
}
