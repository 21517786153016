import { OBSERVER } from '../plugins';

export default function uniqueDistributionLikerts() {
  const onChange = (e) => {
    const currentRadio = e.currentTarget;
    const uniqueLikertsContainer = currentRadio.closest('.c-likerts-survey-field__subjects');
    const stepColumn = currentRadio.dataset.step;
    const stepColumnsRadios = uniqueLikertsContainer.querySelectorAll(`input[data-step="${stepColumn}"]:checked`);
    for (let i = 0; i < stepColumnsRadios.length; i += 1) {
      if (stepColumnsRadios[i].id !== currentRadio.id) {
        stepColumnsRadios[i].checked = false;
      }
    }
  };

  OBSERVER.add({
    name: 'uniqueDistributionLikerts',
    events: 'change',
    targets: '.js-unique-likerts input[type="radio"]',
    function: onChange,
  });
  OBSERVER.on('uniqueDistributionLikerts');
}
