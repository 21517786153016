import { OBSERVER } from '../plugins';

// Fonction permettant de donner un attribut data section closing au html
export default function addDataSectionClosingAttributeToHtml(classOverlay, nameEventOverlay) {
  function onTrigger() {
    const html = document.querySelector('html');
    html.dataset.sectionClosing = html.dataset.section;
  }

  function onCloseComplete() {
    const html = document.querySelector('html');
    html.dataset.sectionClosing = '';
  }

  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: `onTriggerOverlay${nameEventOverlay}`,
    function: onTrigger,
  });
  OBSERVER.add({
    name: `overlay${nameEventOverlay}`,
    events: `onCloseCompleteOverlay${nameEventOverlay}`,
    function: onCloseComplete,
  });
  OBSERVER.on(`overlay${nameEventOverlay}`);
}
