import Overlay from '../vendor/overlay';

// Fonction gérant l'overlay calendrier
export default function overlayCalendar() {
  const overlay = new Overlay({
    name: 'calendar',
    events: {
      open: true,
      closeComplete: true,
    },
    click: {
      buttons: {
        open: '.js-open-overlay-calendar',
        close: '.js-close-overlay-calendar',
      },
    },
    options: {
      speed: 800,
    },
  });

  overlay.init();
}
