// Englobe-les iframes de vidéos youtube pour gérer le ratio
export default function wrapYtVideo() {
  let i;
  let youtubeVideosOuterHtml;
  let newYoutubeVideosOuterHtml;
  const youtubeVideos = document.querySelectorAll('.js-cms-content iframe[src*="youtube.com"], .js-cms-content iframe[src*="vimeo.com"]');
  const youtubeVideosLength = youtubeVideos.length;

  for (i = 0; i < youtubeVideosLength; i += 1) {
    youtubeVideosOuterHtml = youtubeVideos[i].outerHTML;
    newYoutubeVideosOuterHtml = `<div class='videoWrapper'><div class='ytVideo'>${youtubeVideosOuterHtml}</div></div>`;
    youtubeVideos[i].outerHTML = newYoutubeVideosOuterHtml;
  }
}
