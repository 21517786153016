import 'container-query-polyfill';
import '@fancyapps/fancybox/dist/jquery.fancybox';
import Lazyload from 'vanilla-lazyload';
import cssHasPseudo from 'css-has-pseudo/browser';
import CharacterCount from '@blankonumerique/blanko-scripts/character-count';
import Scroller from '@blankonumerique/blanko-scripts/scroller';
import PageTransition from './classes/PageTransition';
import { SCROLLFIRE } from './plugins';
import backendKeyBind from './functions/backendKeyBind';
import maskPhone from './masks/maskPhone';
import maskPostalCode from './masks/maskPostalCode';
import maskCitizenCard from './masks/maskCitizenCard';
import formExample from './validation/formExample';
import formJobs from './validation/formJobs';
import formContact from './validation/formContact';
import formNewsletter from './validation/formNewsletter';
import formNewsletterFooter from './validation/formNewsletterFooter';
import hundredVH from './functions/hundredVH';
import hundredVW from './functions/hundredVW';
import activeScrollfireInViewport from './functions/activeScrollfireInViewport';
import customizedButtons from './functions/customizedButtons';
import wrapYtVideo from './cms-content/wrapYtVideo';
import adjustButtonsMargins from './cms-content/adjustButtonsMargins';
import manageImageLinks from './cms-content/manageImageLinks';
import zoomFontSize from './functions/zoomFontSize';
import overlayCookies from './overlays/overlayCookies';
import overlayShare from './overlays/overlayShare';
import overlayPopup from './overlays/overlayPopup';
import overlayMenuDesktop from './overlays/overlayMenuDesktop';
import overlayMenuMobile from './overlays/overlayMenuMobile';
import overlaySearch from './overlays/overlaySearch';
import overlayCalendar from './overlays/overlayCalendar';
import overlayFilterByDates from './overlays/overlayFilterByDates';
import visibilityAlerts from './functions/visibilityAlerts';
import swiperFilter from './swipers/swiperFilter';
import accordionsRubrics from './functions/accordionsRubrics';
import accordionsSideNavigationLevel2InAside from './functions/accordionsSideNavigationLevel2InAside';
import calendar from './calendar/calendar';
import calendarInput from './calendar/calendarInput';
import inputsAndTextAreaLabel from './form/inputsAndTextAreaLabel';
import textareaHeight from './form/textareaHeight';
import clearInput from './form/clearInput';
import fileUpload from './form/fileUpload';
import multipleFilesUpload from './form/multipleFilesUpload';
import timepicker from './form/timePicker';
import slider from './form/slider';
import stars from './form/stars';
import placeholderBasedOnScreenWidth from './form/placeholderBasedOnScreenWidth';
import toggleFieldContainer from './functions/fieldContainerController';
import removeHoverOnMobile from './functions/removeHoverOnMobile';
import personnalizeWidgetJobillico from './widgets/jobillico';
import otherVisibility from './form/otherVisibility';
import limitedCheckboxLists from './form/limitedCheckboxLists';
import uniqueDistributionLikerts from './form/uniqueDistributionLikerts';
import homeFastSearchInputPlaceholder from './functions/homeFastSearchInputPlaceholder';
import swiperHomeDatesToRemember from './swipers/swiperHomeDatesToRemember';
import overlayOrganization from './overlays/overlayOrganization';
import listOfOrganizations, { resetURL } from './functions/listOfOrganizations';
import loadMoreRegulationCard, { updateLoadMoreRegulationCard } from './functions/loadMoreRegulationCard';
import formRegistrationGarageSale from './validation/formRegistrationGarageSale';
import swiperPublications from './swipers/swiperPublications';

/*
  Vérifie si un élément existe à chaque x ms pendant x ms
  - Selector: Classe ou id de l'élément
  - Callback: Fonction à exécuter si l'élément est trouvé
  - CheckFrequencyInMs: Fréquence des checks à chaque x millisecondes
  - timeoutInMs: Temps en millisecondes après lequel la fonction arrêtera de chercher l'élément même s'il n'est pas trouvé
*/
function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
  let startTimeInMs = Date.now();
  (function loopSearch() {
    if (document.querySelector(selector)) {
      callback(); // Callback qui exécute le code dans la variable
      return;
    } else {
      setTimeout(function () {
        if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) {
          return;
        }
        loopSearch(); // Loop récursif
      }, checkFrequencyInMs);
    }
  })();
}

// Clear les cookies des catégories désactivés (Domaine interne seulement)
function updateCookies(reload = true) {
  let cookies = document.cookie.split('; ');

  if (cookies.some(str => str.includes('cc_cookie_byscuit'))) {
    const cookieCategories = cc.getAllBlocks(); // Toutes les catégories
    const acceptedCats = cc.getUserPreferences().accepted_categories; // Catégories activés

    cookieCategories.forEach(category => {
      // Si la catégorie à des cookies et qu'elle est activé par le user
      if (category.cookie_table && acceptedCats.includes(category.toggle.value)) {
        // Retire le cookie de la liste de cookies à effacer
        category.cookie_table.forEach(cookie => {
          cookies = cookies.filter(name => !name.includes(cookie.COL1));
        })
      }
    });
  }

  // Loop sur chaque cookies du sites qui ne sont pas dans des catégories de Byscuit activés
  cookies.forEach(cookie => {
    let url = window.location.hostname.split('.'); // Split le domaine dans un array à chaque .

    // Loop pour essayer tous les domaines que les cookies peuvent utiliser. Ex: dev.villemontlaurier.qc.ca -> villemontlaurier.qc.ca -> qc.ca -> ca
    while (url.length > 0) {
      // On redéfinit la date d'expiration du cookie au 1 Janvier 1970 pour qu'il se fasse supprimer
      let expiredCookie = `${encodeURIComponent(cookie.split(';')[0].split('=')[0])}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${url.join('.')} ;path=/`;
      document.cookie = expiredCookie;

      let path = location.pathname.split('/'); // On split le path actuel dans un array à chaque /

      // Loop pour essayer tous les paths que les cookies peuvent utiliser
      while (path.length > 0) {
        document.cookie = expiredCookie + path.join('/'); // Rajoute le path au cookie pour être sûr de supprimer tous les cookies
        path.pop(); // Pop le path pour essayer la combinaison suivante
      };

      url.shift(); // Shift le domaine pour essayer la combinaison suivante
    }
  });

  if (reload) {
    // Reload la page pour que Byscuit supprime les cookies externes des catégories désactivées
    location.reload();
  }
}

export default class Namespace {
  // GETTERS / SETTERS
  get scrollfire() {
    return SCROLLFIRE;
  }

  get lazyload() {
    return this._lazyload;
  }

  get scroller() {
    return Scroller;
  }

  get overlayOrganization() {
    return this._overlayOrganization;
  }

  get listOfOrganizations() {
    return this._listOfOrganizations;
  }

  get accordionsDirectory() {
    return this._accordionsDirectory;
  }

  get resetURL() {
    return this._resetURL;
  }

  get overlaysShare() {
    return this._overlaysShare;
  }

  get overlay_share() {
    return this._overlay_share;
  }

  get updateLoadMoreRegulationCard() {
    return this._updateLoadMoreRegulationCard;
  }

  // CONSTRUCTOR
  constructor() {
    this.classPageTransition = new PageTransition();
  }

  // PUBLIC METHODS
  init() {
    this.beforeEnter();

    // CLASS PAGE TRANSITION
    this.classPageTransition.init();

    // CUSTOM EVENT PAGE TRANSITION INEND
    document.addEventListener('inEnd', () => {
      // SCROLLFIRE
      SCROLLFIRE.init();

      // ACTIVE SCROLLFIRE IN VIEWPORT
      activeScrollfireInViewport();
    });

    // Quand Byscuit est init on ajoute les eventlistener pour updater les cookies
    waitForElementToDisplay('#s-rall-bn',function(){
      updateCookies(false);

      // Boutons accepter, refuser et sauvegarder dans la sidebar
      document.querySelector('#s-all-bn').addEventListener('click', updateCookies);
      document.querySelector('#s-rall-bn').addEventListener('click', updateCookies);
      document.querySelector('#s-sv-bn').addEventListener('click', updateCookies)

      // Si le cookie cc_cookie_byscuit existe le bouton #c-t-bn et #c-p-bn sont pas init
      const btnDeclinePopup = document.querySelector('#c-t-bn');
      if (btnDeclinePopup) {
        // Boutons accepter et refuser dans le popup
        document.querySelector('#c-p-bn').addEventListener('click', updateCookies);
        btnDeclinePopup.addEventListener('click', updateCookies);
      }
    }, 1000, 10000);

    this.afterEnter();

    // LAZYLOAD
    this._lazyload = new Lazyload();
  }

  beforeEnter() {
    // CSS HAS PSEUDO
    cssHasPseudo(document);

    // CUSTOMIZED BUTTONS
    customizedButtons();

    // WRAP YOUTUBE VIDEO
    if (document.querySelectorAll('.js-cms-content iframe[src*="youtube.com"], .js-cms-content iframe[src*="vimeo.com"]').length > 0) {
      wrapYtVideo();
    }

    // ADJUST BUTTONS MARGINS
    if (document.querySelectorAll('.js-cms-content p > a.primary-button, .js-cms-content p > a.secondary-button').length > 0) {
      adjustButtonsMargins();
    }

    // MANAGE IMAGE LINKS
    if (document.querySelector('.js-cms-content a') !== null) {
      manageImageLinks();
    }
  }

  afterEnter() {
    // SCROLLER
    Scroller.init();

    // CHARACTER COUNT
    CharacterCount.init();

    // BACKEND KEY BIND
    backendKeyBind();

    // ZOOM FONT-SIZE
    if (document.querySelector('.js-zoom-font-size-option') !== null) {
      zoomFontSize();
    }

    // OVERLAY COOKIES
    if (document.querySelector('[data-overlay="cookies"]') !== null) {
      overlayCookies();
    }

    // OVERLAY SHARE
    if (document.querySelector('[data-overlay="share"]') !== null) {
      this._overlaysShare = overlayShare;
      this._overlay_share = overlayShare();
    }

    // OVERLAY POPUP
    if (document.querySelector('[data-overlay="popup"]') !== null) {
      overlayPopup();
    }

    // OVERLAY MENU DESKTOP
    if (document.querySelector('[data-overlay="menu-desktop"]') !== null) {
      overlayMenuDesktop();
    }

    // OVERLAY MENU MOBILE
    if (document.querySelector('[data-overlay="menu-mobile"]') !== null) {
      overlayMenuMobile();
    }

    // OVERLAY SEARCH
    if (document.querySelector('[data-overlay="search"]') !== null) {
      overlaySearch();
    }

    // OVERLAY CALENDAR
    if (document.querySelector('[data-overlay="calendar"]') !== null) {
      overlayCalendar();
    }

    // OVERLAY FILTER BY DATES
    if (document.querySelector('[data-overlay="filter-by-dates"]') !== null) {
      overlayFilterByDates();
    }

    // ACCORDIONS RUBRICS
    if (document.querySelector('.js-accordions') !== null) {
      accordionsRubrics();
    }

    // ACCORDIONS SIDE NAVIGATION LEVEL 2 IN ASIDE
    if (document.querySelector('.js-accordions-side-navigation-level-2-in-aside') !== null) {
      accordionsSideNavigationLevel2InAside();
    }

    // CALENDAR
    if (document.querySelector('.js-calendar') !== null) {
      calendar();
    }

    // CALENDAR INPUT
    if (document.querySelector('.js-input-field-calendar') !== null) {
      calendarInput();
    }

    // INPUTS AND TEXTAREA LABEL
    if (document.querySelector('input[type="text"], input[type="email"], input[type="search"], input[type="tel"], textarea') !== null) {
      inputsAndTextAreaLabel();
    }

    // TEXTAREA HEIGHT
    if (document.querySelector('textarea') !== null) {
      textareaHeight();
    }

    // CLEAR INPUT
    if (document.querySelector('.js-input-to-clear') !== null) {
      clearInput();
    }

    // FILE UPLOAD
    if (document.querySelector('.js-file-field-input') !== null) {
      fileUpload();
    }

    // MULTIPLE FILES UPLOAD
    if (document.querySelector('.js-multiple-files-field-button-input') !== null) {
      multipleFilesUpload();
    }

    // TIME PICKER
    if (document.querySelector('.js-input-field-time .js-input-field-input') !== null) {
      timepicker();
    }

    // SLIDER
    if (document.querySelector('input[type="range"]') !== null) {
      slider();
    }

    // STARS
    if (document.querySelector('.js-raty-stars') !== null) {
      stars();
    }

    // PLACEHOLDER BASED ON SCREEN WIDTH
    if (document.querySelector('input[data-placeholder-based-on-screen-width]') !== null) {
      placeholderBasedOnScreenWidth();
    }

    // MASK PHONE
    if (document.querySelector('.js-mask-phone') !== null) {
      maskPhone();
    }

    // MASK POSTAL CODE
    if (document.querySelector('.js-mask-postal-code') !== null) {
      maskPostalCode();
    }

    // MASK CITIZEN CARD
    if (document.querySelector('.js-mask-citizen-card') !== null) {
      maskCitizenCard();
    }

    // FORM EXAMPLE
    if (document.querySelector('#form-example') !== null) {
      formExample();
    }

    // FORM JOBS
    if (document.querySelector('#form-jobs') !== null) {
      formJobs();
    }

    // FORM CONTACT
    if (document.querySelector('#form-contact') !== null) {
      formContact();
    }

    // FORM NEWSLETTER
    if (document.querySelector('#form-newsletter') !== null) {
      formNewsletter();
    }

    // FORM NEWSLETTER FOOTER
    if (document.querySelector('#form-newsletter-footer') !== null) {
      formNewsletterFooter();
    }

    // TOGGLE FIELD CONTAINER
    if (document.querySelector('#form-registration-garage-sale-cat-other') !== null) {
      toggleFieldContainer();
    }

    // REMOVE HOVER ON MOBILE
    if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      removeHoverOnMobile();
    }

    // HUNDRED VH
    hundredVH();

    // HUNDRED VW
    hundredVW();

    // VISIBILITY ALERTS
    if (document.querySelector('.js-alert') !== null) {
      visibilityAlerts();
    } else {
      document.documentElement.style.setProperty('--alert-index-0-height', '0px');
      document.documentElement.style.setProperty('--alert-index-1-height', '0px');
    }

    // SWIPER FILTER
    if (document.querySelector('[data-swiper="filter"]')) {
      swiperFilter();
    }

    // PERSONNALIZE WIDGET JOBILLICO
    if (document.querySelector('#jobillico-widget') !== null) {
      personnalizeWidgetJobillico();
    }

    // OTHER VISIBILITY
    if (document.querySelector('.js-input-value-other') !== null) {
      otherVisibility();
    }

    // LIMITED CHECKBOX LISTS - TAG
    if (document.querySelector('.js-limited-chexboxlist-tag') !== null) {
      limitedCheckboxLists('.js-limited-chexboxlist-tag', 'c-checkbox-tag-field__label--disabled');
    }

    // LIMITED CHECKBOX LISTS - CHECKBOX
    if (document.querySelector('.js-limited-chexboxlist-field') !== null) {
      limitedCheckboxLists('.js-limited-chexboxlist-field', 'c-checkbox-field__label--disabled');
    }

    // LIMITED CHECKBOX LISTS - SURVEY CHECKBOX
    if (document.querySelector('.js-limited-chexboxlist') !== null) {
      limitedCheckboxLists('.js-limited-chexboxlist', 'c-checkbox-survey-field__label--disabled');
    }

    // LIMITED CHECKBOX LISTS - SURVEY THUMBNAIL
    if (document.querySelector('.js-limited-thumbnaillist') !== null) {
      limitedCheckboxLists('.js-limited-thumbnaillist', 'c-thumbnail-survey-field__label--disabled');
    }

    // UNIQUE DISTRIBUTION LIKERTS
    if (document.querySelector('.js-unique-likerts') !== null) {
      uniqueDistributionLikerts();
    }

    // SWIPER PUBLICATIONS
    if (document.querySelector('.js-component-publications-swiper') !== null
      && document.querySelector('[data-swiper="publications"]') !== null) {
      swiperPublications();
    }
  }

  standard() {
    this.init();
  }

  home() {
    this.init();

    // HOME FAST SEARCH INPUT PLACEHOLDER
    if (document.querySelector('.js-home-fast-search-search-field-input') !== null) {
      homeFastSearchInputPlaceholder();
    }

    // SWIPER HOME DATES TO REMEMBER
    if (document.querySelector('[data-swiper="home-dates-to-remember"]')) {
      swiperHomeDatesToRemember();
    }
  }

  directoryOfOrganizations() {
    this.init();

    // OVERLAY ORGANIZATION & DIRECTORY OF ORGANIZATIONS
    if (document.querySelector('.js-accordions-sub-category-organization') !== null) {
      // OVERLAY ORGANIZATION
      if (document.querySelector('[data-overlay="organization"]') !== null) {
        this._overlayOrganization = overlayOrganization();
      }

      // DIRECTORY OF ORGANIZATIONS
      this._listOfOrganizations = listOfOrganizations;
      this._accordionsDirectory = listOfOrganizations();

      // RESET URL
      this._resetURL = resetURL;
    }
  }

  directoryOfRegulationsAndZoning() {
    this.init();

    // LOAD MORE REGULATION CARD & UPDATE LOAD MORE REGULATION CARD
    if (document.querySelector('.js-button-load-more-container') !== null) {
      loadMoreRegulationCard();
      this._updateLoadMoreRegulationCard = updateLoadMoreRegulationCard;
    }
  }

  registrationGarageSale() {
    this.init();

    // FORM REGISTRATION GARAGE SALE
    if (document.querySelector('#form-registration-garage-sale') !== null) {
      formRegistrationGarageSale();
    }
  }

  map() {
    this.init();

    // CUSTOM EVENT MAP MAPSHOWPANELVIEWLOCATIONDETAIL
    document.addEventListener('mapShowPanelViewLocationDetail', () => {
      // CUSTOMIZED BUTTONS
      customizedButtons();

      // WRAP YOUTUBE VIDEO
      if (document.querySelectorAll('.js-cms-content iframe[src*="youtube.com"], .js-cms-content iframe[src*="vimeo.com"]').length > 0) {
        wrapYtVideo();
      }

      // ADJUST BUTTONS MARGINS
      if (document.querySelectorAll('.js-cms-content p > a.primary-button, .js-cms-content p > a.secondary-button').length > 0) {
        adjustButtonsMargins();
      }

      // MANAGE IMAGE LINKS
      if (document.querySelector('.js-cms-content a') !== null) {
        manageImageLinks();
      }
    });
  }
}
