import Accordions from '../classes/Accordions';

// Permet d'initialiser les accordions de 2em niveau dans l'aside
export default function accordionsSideNavigationLevel2InAside() {
  const sideNavigationLevel2InAside = new Accordions({
    containerClass: 'js-accordions-side-navigation-level-2-in-aside',
    accordionClass: 'js-accordion-side-navigation-level-2-in-aside',
    toggleButtonClass: 'js-accordion-side-navigation-toggle-level-2-in-aside',
    contentClass: 'js-accordion-side-navigation-content-level-2-in-aside',
    scrollToAccordion: false,
    allIsClosed: (document.querySelector('.js-accordion-side-navigation-level-2-in-aside.js-accordion-opened') === null),
  });
  sideNavigationLevel2InAside.offset = 0;
  sideNavigationLevel2InAside.init();
}
