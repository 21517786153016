import $ from 'jquery';
import Overlay from '../vendor/overlay';
import addDataSectionClosingAttributeToHtml from './addDataSectionClosingAttributeToHtml';
import { OBSERVER } from '../plugins';

// Fonction gérant l'overlay de recherche
export default function overlaySearch() {
  const overlay = new Overlay({
    name: 'search',
    events: {
      openComplete: true,
      closeComplete: true,
      trigger: true,
    },
    click: {
      buttons: {
        close: 'a[href], .js-close-overlay-search',
        trigger: '.js-trigger-overlay-search',
        switch: '.js-trigger-overlay-menu-desktop, .js-toggle-overlay-menu-mobile',
      },
    },
    animations: {
      selector: '.js-overlay-search-sections',
      styles: [{
        property: 'height',
        value: 'dataset',
        easing: 'easeInOutQuart',
      }],
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      closeOnResize: false,
    },
  });

  overlay.init();

  // Fonction permettant de donner un attribut data section closing au html (mettre events closeComplete et trigger à true)
  addDataSectionClosingAttributeToHtml(overlay, 'Search');

  // Permet de focus le champ de recherche
  function onOpenComplete() {
    const inputSearch = document.querySelector('[data-overlay="search"] .js-overlay-search-search-field-input');

    inputSearch.focus();
  }

  // Permet de vider le contenu du champ de recherche
  function onCloseComplete() {
    const inputSearch = document.querySelector('[data-overlay="search"] .js-overlay-search-search-field-input');

    inputSearch.value = '';
  }

  // Permet de donner à chaque fast result card la plus grande largeur possible à la catégorie container
  function setFastResultCardCategoryContainerWidth() {
    const fastResultCardCategoryContainer = document.querySelectorAll('.js-fast-result-card-category-container');
    let fastResultCardCategoryContainerMaxWidth = 0;

    for (let i = 0; i < fastResultCardCategoryContainer.length; i += 1) {
      fastResultCardCategoryContainerMaxWidth = Math.max(fastResultCardCategoryContainerMaxWidth, fastResultCardCategoryContainer[i].offsetWidth);
    }

    for (let i = 0; i < fastResultCardCategoryContainer.length; i += 1) {
      fastResultCardCategoryContainer[i].style.width = `${fastResultCardCategoryContainerMaxWidth}px`;
    }
  }

  let ajaxRequest = false;

  // Permet de changer de section
  function changeSection(e) {
    const inputSearch = e.target;
    const html = document.querySelector('html');

    if (ajaxRequest) {
      // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
      ajaxRequest.abort();
      if (html.dataset.section !== 'suggestions') {
        overlay.trigger('suggestions');
      }
    }

    if (inputSearch.value.length >= 3) {
      ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
        data: { q: inputSearch.value },
        update: { 'rechercheAutocomplete::autocomplete': '#autocomplete' },
        complete(data) {
          // Results loaded.
          ajaxRequest = false;

          // On affiche l'overlay des suggestions juste si ya des résultats
          if (data && data['rechercheAutocomplete::autocomplete']) {
            if (html.dataset.section !== 'fast-results') {
              overlay.trigger('fast-results');
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (html.dataset.section !== 'suggestions') {
              overlay.trigger('suggestions');
            }
          }
        },
      });

      setFastResultCardCategoryContainerWidth();
    } else if (inputSearch.value.length < 3 && html.dataset.section !== 'suggestions') {
      document.querySelector('[data-overlay="search"] .js-overlay-search-section[data-section="suggestions"]').scrollTop = 0;
      overlay.trigger('suggestions');
    }
  }

  // Permet de changer de section quand on efface tout le contenu du champ de recherche et de redonner le focus au champ de recherche
  function onClearInput() {
    const inputSearch = document.querySelector('[data-overlay="search"] .js-overlay-search-search-field-input');
    const html = document.querySelector('html');

    if (html.dataset.section !== 'suggestions') {
      document.querySelector('[data-overlay="search"] [data-section="suggestions"]').scrollTop = 0;
      overlay.trigger('suggestions');
    }

    inputSearch.focus();
  }

  // Mettre à jour la largeur des catégories dans les fast result card lorsqu'on redimensionne l'écran
  function onResize() {
    const html = document.querySelector('html');

    if (html.dataset.section === 'fast-results' && window.matchMedia('(min-width: 601px)').matches) {
      setFastResultCardCategoryContainerWidth();
    }
  }

  OBSERVER.add({
    name: 'overlaySearch',
    events: 'onOpenCompleteOverlaySearch',
    function: onOpenComplete,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'onCloseCompleteOverlaySearch',
    function: onCloseComplete,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'input',
    targets: '.js-overlay-search-search-field-input',
    function: changeSection,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'click',
    targets: '.js-overlay-search-search-field-button-delete',
    function: onClearInput,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'resize',
    function: onResize,
  });
  OBSERVER.on('overlaySearch');
}
