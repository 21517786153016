import $ from 'jquery';
import ShareButtons from 'share-buttons/dist/share-buttons';
import Accordions from '../classes/Accordions';
import { OBSERVER } from '../plugins';

// Permet d'initialiser les accordions du répertoire d'organisme
export default function listOfOrganizations() {
  const accordionsSubCategoryOrganization = new Accordions({
    containerClass: 'js-accordions-sub-category-organization',
    accordionClass: 'js-accordion-sub-category-organization',
    toggleButtonClass: 'js-accordion-sub-category-organization-toggle',
    contentClass: 'js-accordion-sub-category-organization-content',
  });
  accordionsSubCategoryOrganization.offset = 0;
  accordionsSubCategoryOrganization.init();

  let [, slugPage, slugSubCategory, slugOrganization] = window.location.pathname.split('/');

  // Mettre à jour les variables contenants les différents slugs
  function updateVariablesSlugs() {
    [, slugPage, slugSubCategory, slugOrganization] = window.location.pathname.split('/');
  }

  // Loader le contenu de l'overlay organization et ouvrir l'overlay
  function loadOverlayOrganisation() {
    // Call en ajax l'update du contenu de l'overlay
    $.request('onLoadOverlay', {
      data: { slug: slugOrganization },
      complete: () => {
        window.namespace.overlayOrganization.open();
        window.namespace.overlay_share.destroy();
        setTimeout(() => {
          window.namespace.overlaysShare();
        }, 1000);
      },
    });
  }

  // Retourne l'offset du scrollfire qui contient l'accordion
  function getScrollfireOffsetAccordionSubCategory(accordion) {
    const accordionContainerScrollfire = accordion.closest('.js-scrollfire');

    let matrix;
    if (accordionContainerScrollfire) {
      matrix = window.getComputedStyle(accordionContainerScrollfire, null).getPropertyValue('transform');
    } else {
      matrix = 'none';
    }

    let scrollfireOffset;
    if (matrix !== 'none') {
      const stringArrayMatrix = matrix.replace('matrix(', '').replace(')', '');
      const arrayMatrix = JSON.parse(`[${stringArrayMatrix}]`);
      [,,,,, scrollfireOffset] = arrayMatrix;
    } else {
      scrollfireOffset = 0;
    }

    if (accordionContainerScrollfire) {
      return -scrollfireOffset;
    }

    return 0;
  }

  // Condition au load de page, afin d'ouvrir l'accordion et l'overlay organization selon la présence de slugs
  if (slugSubCategory) {
    const accordions = document.querySelectorAll('.js-accordion-sub-category-organization');
    let found = false; // Si on trouve bien une rubrique qui correspond au slug

    for (let i = 0; i < accordions.length; i += 1) {
      if (accordions[i].dataset.slug === slugSubCategory) {
        accordionsSubCategoryOrganization.open(accordions[i], getScrollfireOffsetAccordionSubCategory(accordions[i]));
        found = true;
        if (slugOrganization) {
          setTimeout(() => { loadOverlayOrganisation(); }, 1100);
        }
        break;
      }
    }

    // Si aucune rubrique correspond, c'est un slug d'entreprise
    if (!found) {
      $.request('onLoadOverlay', {
        data: { slug: slugSubCategory },
        complete: () => {
          window.namespace.overlayOrganization.open();
          window.namespace.overlay_share.destroy();
          setTimeout(() => {
            window.namespace.overlaysShare();
          }, 1000);
        },
      });
    }
  }

  // Permet de changer la pathname quand un accordion est ouvert ou bien fermer
  function onOpenAccordionSubCategory(e) {
    const accordionToggle = e.target;
    const accordion = accordionToggle.closest('.js-accordion-sub-category-organization');

    updateVariablesSlugs();
    if (accordion.classList.contains('js-accordion-opened')) {
      window.history.pushState({}, '', `/${slugPage}/${accordion.dataset.slug}`);
    } else {
      window.history.pushState({}, '', `/${slugPage}`);
    }
  }

  // Permet de loader le contenu de l'overlay organization et de changer le pathname quand un l'overlay est ouvert ou bien fermer
  function onOpenOverlayOrganization(e) {
    const organization = e.target;
    updateVariablesSlugs();
    loadOverlayOrganisation();

    // Si on est en vue filtré (pas de rubrique, donc pas de slug de categ)
    if (!slugSubCategory) {
      window.history.pushState({}, '', `/${slugPage}/${organization.dataset.slug}`);
    } else {
      window.history.pushState({}, '', `/${slugPage}/${slugSubCategory}/${organization.dataset.slug}`);
    }

    updateVariablesSlugs();
  }

  function onCloseOverlayOrganization() {
    // Si on a un organisme direct (sans slug de categ)
    if (!slugOrganization) {
      window.history.pushState({}, '', `/${slugPage}`);
    } else {
      window.history.pushState({}, '', `/${slugPage}/${slugSubCategory}`);
    }
  }

  OBSERVER.add({
    name: 'listOfOrganizations',
    events: 'click',
    targets: '.js-accordion-sub-category-organization-toggle',
    function: onOpenAccordionSubCategory,
  });
  OBSERVER.add({
    name: 'listOfOrganizations',
    events: 'click',
    targets: '.js-organization-card',
    function: onOpenOverlayOrganization,
  });
  OBSERVER.add({
    name: 'listOfOrganizations',
    events: 'click',
    targets: '.js-close-overlay-organization',
    function: onCloseOverlayOrganization,
  });
  OBSERVER.on('listOfOrganizations');

  return accordionsSubCategoryOrganization;
}

// permet de réinitialiser l'URL du navigateur et de mettre à jour les boutons de partage.
export function resetURL() {
  // eslint-disable-next-line no-restricted-globals
  history.pushState({}, '', `/${window.location.pathname.split('/')[1]}`);
  document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
  ShareButtons.update();
}
