/**
 * Enregistre et restaure la position de défilement pour une page précédente.
 * Si `nowPathname` est fourni, le défilement sera restauré uniquement si l'utilisateur arrive de cette page.
 * Sinon, le défilement sera restauré peu importe la page d'origine.
 * @param {string} nowPathname - Le chemin d'accès de la page actuelle à partir de laquelle l'utilisateur arrive.
 * @param {string} previousPathname - Le chemin d'accès de la page précédente à laquelle l'utilisateur souhaite retourner.
 */
function keepScrollPositionOfPreviousPage(nowPathname, previousPathname) {
  // Enregistre la position de scroll lorsqu'on quitte la page précédente
  const outStart = () => {
    if (window.location.pathname === previousPathname) {
      localStorage.setItem('scrollPositionOfPreviousPage', window.scrollY.toString());
    }
  };

  // Restaure la position de scroll lorsqu'on revient à la page précédente
  const inStart = () => {
    if (window.location.pathname === previousPathname && localStorage.getItem('scrollPositionOfPreviousPage')) {
      const scrollPosition = parseInt(localStorage.getItem('scrollPositionOfPreviousPage'), 10);
      localStorage.removeItem('scrollPositionOfPreviousPage');

      // Si `nowPathname` est fourni, on vérifie que l'utilisateur arrive de cette page avant de restaurer la position de scroll.
      // Sinon, on restaure la position de scroll peu importe la page d'origine.
      if (!nowPathname || document.referrer.endsWith(nowPathname)) {
        window.scrollTo(0, scrollPosition);
      }
    }
  };

  // Ajoute-les listeners aux événements outStart et inStart
  document.addEventListener('outStart', outStart);
  document.addEventListener('inStart', inStart);
}

/**
 * Obtient l'URL relative en supprimant le domaine et en découpant les parties inutiles (comme les ancres).
 * @param {string} url - L'URL à convertir en URL relative.
 * @returns {string} L'URL relative.
 */
function getRelativeUrl(url) {
  // eslint-disable-next-line no-useless-escape
  return `/${url.replace(/^(?:\/\/|[^\/]+)*\//, '').split('#')[0]}`;
}

/**
 * PageTransition - Classe JavaScript permettant d'avoir une transition d'entrée et de sortie d'une page.
 * @version 1.6
 * @date 2024-03-12
 */
export default class PageTransition {
  constructor(object = {}) {
    // Élément HTML de la page
    this.html = document.documentElement;

    // Classe CSS utilisée pour empêcher l'ajout de l'écouteur d'événement de transition de page aux liens spécifiques
    this.linkPreventClass = (object.linkPreventClass !== undefined) ? object.linkPreventClass : 'js-page-transition-link-prevent';

    // Sélecteur des liens qui ajoutent une transition de page
    this.linkTarget = (object.linkTarget !== undefined) ? object.linkTarget : `a[href]:not([href^="#"]):not([href^="tel"]):not([href^="mailto"]):not([target="_blank"]):not(.${this.linkPreventClass})`;

    // Classe CSS ajoutée aux liens initialisés avec un écouteur d'événements pour éviter d'ajouter plusieurs fois l'écouteur sur les mêmes liens
    this.linkClassInitialized = (object.linkClassInitialized !== undefined) ? object.linkClassInitialized : 'js-page-transition-link-initialized';

    // Classe CSS pour l'animation de transition entrante
    this.inClass = (object.inClass !== undefined) ? object.inClass : 'page-transition-in';

    // Classe CSS pour l'animation de transition sortante
    this.outClass = (object.outClass !== undefined) ? object.outClass : 'page-transition-out';

    // Classe CSS pour afficher la transition de page
    this.showClass = (object.showClass !== undefined) ? object.showClass : 'show-page-transition';

    // Classe CSS pour afficher la transition de la première page
    this.showFirstPageClass = (object.showFirstPageClass !== undefined) ? object.showFirstPageClass : 'show-first-page-transition';

    // Durée de la transition out
    this.durationOut = (object.durationOut !== undefined) ? object.durationOut : 400;

    // Durée de la transition in
    this.durationIn = (object.durationIn !== undefined) ? object.durationIn : 400;

    // Durée de la transition in de la première page
    this.durationFirstPageIn = (object.durationFirstPageIn !== undefined) ? object.durationFirstPageIn : 400;

    // Délai de la transition out
    this.delayOut = (object.delayOut !== undefined) ? object.delayOut : 0;

    // Délai de la transition in
    this.delayIn = (object.delayIn !== undefined) ? object.delayIn : 0;

    // Délai de la transition in de la première page
    this.delayFirstPageIn = (object.delayFirstPageIn !== undefined) ? object.delayFirstPageIn : 0;

    // Détermine si la page est la première page
    this.isFirstPage = false;

    // Tableau des URL des pages précédemment visitées pour lesquelles la position de défilement doit être conservée lors de la transition vers la nouvelle page
    this.keepScrollPositionOfPreviousPages = (object.keepScrollPositionOfPreviousPages) ? object.keepScrollPositionOfPreviousPages : [];
  }

  init() {
    // Récupère tous les liens ciblés par le sélecteur `this.linkTarget`
    const links = document.querySelectorAll(this.linkTarget);

    // Ajoute des écouteurs d'événements aux liens pour déclencher la transition de page
    links.forEach((link) => {
      // Ajoute des écouteurs d'événements aux liens qui ne mènent pas à la page courante
      if (window.location.pathname !== getRelativeUrl(link.href)) {
        link.addEventListener('click', this.onClick.bind(this));
        link.classList.add(this.linkClassInitialized);
      }
    });

    // Ajouter une classe this.showFirstPageClass si c'est la première page
    if (sessionStorage.getItem('isFirstPage') === null) {
      sessionStorage.setItem('isFirstPage', 'true');
      this.isFirstPage = true;
      this.html.classList.add(this.showFirstPageClass);
    } else {
      sessionStorage.setItem('isFirstPage', 'false');
      this.isFirstPage = false;
    }

    // Appliquer la position de défilement précédente aux pages précédentes
    this.keepScrollPositionOfPreviousPages.forEach((pathnames) => {
      if (pathnames.previousPathname) {
        keepScrollPositionOfPreviousPage((pathnames.nowPathname) ? pathnames.nowPathname : null, pathnames.previousPathname);
      }
    });

    // Résoudre le problème de cache du navigateur du bouton de retour de Chrome et autres navigateurs
    window.addEventListener('unload', () => {});

    // Résoudre le problème de cache du navigateur du bouton de retour de Safari
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        this.in();
      }
    });

    // Appelle la méthode in pour déclencher la transition d'entrée
    this.in();
  }

  onClick(e) {
    // Empêche le comportement par défaut de l'événement click
    e.preventDefault();

    // Récupère l'URL de destination du lien cliqué et déclenche la transition de sortie
    this.out(e.currentTarget.getAttribute('href'));
  }

  // Méthode out pour déclencher la transition de sortie depuis un lien <a> ou un autre élément
  out(destinationUrl) {
    // Déclenche la transition de sortie après un délai égal au délai de la transition de sortie
    setTimeout(() => {
      // Déclenche l'événement 'outStart' pour indiquer que la transition de sortie commence
      document.dispatchEvent(new Event('outStart'));

      // Ajoute la classe this.outClass pour afficher la transition de sortie
      this.html.classList.add(this.outClass);

      // Ajoute la classe this.showClass pour afficher la transition de sortie
      this.html.classList.add(this.showClass);

      // Déclenche la transition de sortie après un délai égal à la durée de la transition de sortie
      setTimeout(() => {
        // Redirige vers l'URL de destination
        window.location.href = destinationUrl;

        // Déclenche l'événement 'outEnd' pour indiquer que la transition de sortie est terminée
        document.dispatchEvent(new Event('outEnd'));
      }, this.durationOut);
    }, this.delayOut);
  }

  in() {
    // Variables pour durée/délai de la transition d'entrée d'une page ou d'une première page
    let durationIn;
    let delayIn;

    // Détermine durée/délai de la transition d'une page ou d'une première page
    if (sessionStorage.getItem('isFirstPage') === 'true') {
      durationIn = this.durationFirstPageIn;
      delayIn = this.delayFirstPageIn;
    } else {
      durationIn = this.durationIn;
      delayIn = this.delayIn;
    }

    // Déclenche la transition d'entrée après un délai égal au délai de la transition d'entrée
    setTimeout(() => {
      // Déclenche l'événement 'inStart' pour indiquer que la transition d'entrée commence
      document.dispatchEvent(new Event('inStart'));

      // Ajoute la classe this.inClass pour afficher la transition d'entrée
      this.html.classList.add(this.inClass);

      // Supprime la classe this.showClass pour masquer la transition de sortie
      this.html.classList.remove(this.showClass);

      // Supprime la classe this.showFirstPageClass pour masquer la transition de sortie de la première page
      this.html.classList.remove(this.showFirstPageClass);

      // Déclenche la transition d'entrée après un délai égal à la durée de la transition d'entrée
      setTimeout(() => {
        // Supprime la classe this.inClass pour terminer la transition d'entrée
        this.html.classList.remove(this.inClass);

        // Déclenche l'événement 'inEnd' pour indiquer que la transition d'entrée est terminée
        document.dispatchEvent(new Event('inEnd'));
      }, durationIn);
    }, delayIn);
  }

  // Méthode pour mettre à jour les liens avec une transition de page après un appel AJAX
  updateLinks() {
    // Sélectionne tous les liens correspondant au sélecteur `this.linkTarget`
    const links = document.querySelectorAll(`${this.linkTarget}:not(${this.linkClassInitialized})`);

    // Ajoute des écouteurs d'événements aux nouveaux liens pour déclencher la transition de page
    links.forEach((link) => {
      link.addEventListener('click', this.onClick.bind(this));
      link.classList.add(this.linkClassInitialized);
    });
  }
}
