import { OBSERVER } from '../plugins';

// Fonction permettant de changer le placeholder d'un champ selon la taille de l'écran
export default function placeholderBasedOnScreenWidth() {
  const inputs = document.querySelectorAll('input[data-placeholder-based-on-screen-width]');

  const setInputPlaceholder = () => {
    inputs.forEach((input) => {
      const mediaAndPlaceholders = input.dataset.placeholderBasedOnScreenWidth.split(':');
      const placeholders = mediaAndPlaceholders[1].split('|');

      const media = mediaAndPlaceholders[0] === undefined ? 0 : mediaAndPlaceholders[0];
      const placeholderMobile = placeholders[0] === undefined ? input.placeholder : placeholders[0];
      const placeholderDesktop = placeholders[1] === undefined ? input.placeholder : placeholders[1];

      if (window.matchMedia(`(max-width: ${media}px)`).matches) {
        input.setAttribute('placeholder', placeholderMobile);
      } else {
        input.setAttribute('placeholder', placeholderDesktop);
      }
    });
  };

  setInputPlaceholder();
  OBSERVER.add({
    name: 'placeholderBasedOnScreenWidth',
    events: 'resize',
    function: setInputPlaceholder,
  });
  OBSERVER.on('placeholderBasedOnScreenWidth');
}
