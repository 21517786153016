import rangeSlider from 'rangeslider-pure';

// Création du champ slider (doc: https://github.com/Stryzhevskyi/rangeSlider)
export default function slider() {
  const sliders = document.querySelectorAll('input[type="range"]');

  if (sliders.length) {
    rangeSlider.create(sliders);
  }
}
