import Overlay from '../vendor/overlay';
import Accordions from '../classes/Accordions';
import { OBSERVER } from '../plugins';

// Fonction gérant l'overlay filtre par dates
export default function overlayFilterByDates() {
  const overlay = new Overlay({
    name: 'filter-by-dates',
    events: {
      close: true,
    },
    click: {
      buttons: {
        open: '.js-open-overlay-filter-by-dates',
        close: '.js-close-overlay-filter-by-dates',
      },
    },
    options: {
      speed: 800,
    },
  });

  overlay.init();

  // Initialiser les accordions
  const accordionsFilterByDates = new Accordions({
    containerClass: 'js-accordions-filter-by-dates',
    accordionClass: 'js-accordion-filter-by-dates',
    toggleButtonClass: 'js-accordion-filter-by-dates-toggle',
    contentClass: 'js-accordion-filter-by-dates-content',
  });
  accordionsFilterByDates.offset = 0;
  accordionsFilterByDates.init();

  function onClose() {
    accordionsFilterByDates.closeAll();
  }

  OBSERVER.add({
    name: 'overlayFilterByDates',
    events: 'onCloseOverlayFilter-by-dates',
    function: onClose,
  });
  OBSERVER.on('overlayFilterByDates');
}
