import Raty from 'raty-js';

// Création du champ stars (doc: https://github.com/wbotelhos/raty)
export default function stars() {
  const themePath = window.config.theme_path;
  const starsField = document.querySelectorAll('.js-raty-stars');

  for (let i = 0; i < starsField.length; i += 1) {
    const raty = new Raty(starsField[i], {
      path: `/themes/${themePath}/assets/medias/images/icons/raty-stars/`,
      starOff: 'star-off.svg',
      starOn: 'star-on.svg',
      starHalf: 'star-half.svg',
      space: false,
      hints: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      targetScore: 'hack',
      scoreName: 'starsField',
    });

    raty.init();
  }
}
