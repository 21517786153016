import ShareButtons from 'share-buttons/dist/share-buttons';
import Overlay from '../vendor/overlay';
import addHeightAttributeToContainer from './addHeightAttributeToContainer';
import { OBSERVER } from '../plugins';

// Fonction gérant l'overlay share
export default function overlayShare() {
  window.overlayShare = new Overlay({
    name: 'share',
    events: {
      open: true,
      closeComplete: true,
    },
    click: {
      buttons: {
        open: '.js-open-overlay-share',
        close: '.js-close-overlay-share',
      },
    },
    options: {
      speed: 800,
    },
  });

  window.overlayShare.init();

  // Fonction permettant de donner un attribut height au container de l'overlay (mettre events open et closeComplete à true)
  addHeightAttributeToContainer(window.overlayShare, 'Share', '[data-overlay-container="share"]');

  // Message de confirmation de copie de l'URL ----------------
  const confirmClipboardCopy = () => {
    setTimeout(() => {
      // eslint-disable-next-line no-alert
      alert('L’adresse URL a bien été copiée dans votre presse-papier.');
    }, 300);
  };

  OBSERVER.add({
    name: 'confirmClipboardCopy',
    events: 'click',
    targets: '.js-share-copy',
    function: confirmClipboardCopy,
  });
  OBSERVER.on('confirmClipboardCopy');

  // Gestion de l'ouverture de l'overlay ----------------------
  const openShareOverlay = (e) => {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  };

  OBSERVER.add({
    name: 'openShareOverlay',
    events: 'click',
    targets: '.js-open-overlay-share',
    function: openShareOverlay,
  });
  OBSERVER.on('openShareOverlay');

  // Gestion de la fermeture de l'overlay ---------------------
  const closeShareOverlay = () => {
    window.history.replaceState(null, null, ' ');
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  };

  OBSERVER.add({
    name: 'closeShareOverlay',
    events: 'click',
    targets: '.js-close-overlay-share',
    function: closeShareOverlay,
  });
  OBSERVER.on('closeShareOverlay');

  return window.overlayShare;
}
